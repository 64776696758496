<template>
    <v-dialog v-model="dialog" max-width="90%">
        <v-card>
            <v-card-title>
                <h3>Report Details Bank Deposit {{
                    formatDateUtcToVn(fullSelectedWeek?.start) }} - {{
                        formatDateUtcToVn(fullSelectedWeek?.end) }} </h3>
                <v-spacer></v-spacer>
                <div class="select-list">
                    <v-autocomplete
                        class="display-mode-select v-autocomplete__nodetails v-autocomplete__noshadow v-autocomplete__borderform v-autocomplete__modappend v-autocomplete__formheight v-autocomplete__template-status"
                        v-model="selectedWeek" :items="dates" item-value="display" :placeholder="'Choose Data'"
                        :color="`#e04038`" dense label="" solo>
                        <template v-slot:append>
                            <div class="v-auto-append-icon">
                                <img src="@/assets/imgs/icons/menu-down-icon.svg" alt="back-icon" height="22px"
                                    class="pointer mx-1" />
                            </div>
                        </template>
                        <template v-slot:item="{ item, on, attrs }">
                            <p class="mb-0 font-weight-regular" v-bind="attrs" v-on="on">
                                {{ item.display }}
                            </p>
                        </template>
                        <template v-slot:selection="{ item, on, attrs }">
                            <p class="mb-0" v-bind="attrs" v-on="on">
                                {{ item.display }}
                            </p>
                        </template>
                    </v-autocomplete>
                </div>

            </v-card-title>
            <v-card-text>
                <div style="position: relative; width: 100%;">
                    <div class="filter-block py-3 px-3">
                        <div class="btn_reload" v-tooltip="'Reload data table'">
                            <img src="@/assets/imgs/icons/sync-icon.svg" alt="company-icon" class="reload__icon__reload"
                                v-on:click="reloadData" />
                        </div>
                        <div class="btn_excel" v-tooltip="'Export to Excel'">
                            <export-excel :data="items_export"
                                :name="`Report Detail Bank Deposit ` + formatDateUtcToVn(fullSelectedWeek?.start) + ' - ' + formatDateUtcToVn(fullSelectedWeek?.end)">
                                <img src="@/assets/imgs/icons/excel-icon.svg" alt="company-icon" class="excel__icon" />
                            </export-excel>
                        </div>
                    </div>
                    <v-overlay :value="loading">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                    <ag-grid-vue id="myGridSidebar" style="width: 100%; height: 650px;" class="ag-theme-alpine"
                        :columnDefs="columnDefs" :rowData="rowData" :domLayout="'autoHeight'"
                        :defaultColDef="defaultColDef" :pagination="true" :paginationPageSize="12" :pivotMode="false"
                        :rowSelection="'multiple'">
                    </ag-grid-vue>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script>
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import axios from 'axios';
import { VAutocomplete } from 'vuetify/lib';

export default {
    components: {
        AgGridVue,
        VAutocomplete
    },
    props: ['selectedCurrency', 'dates'],
    data() {
        return {
            dialog: false,
            loading: false,
            selectedWeek: this.dates && this.dates.length > 0 ? this.dates[0].display : null,
            fullSelectedWeek: null,
            rowData: [],
            columnDefs: [
                {
                    headerName: "STT",
                    valueGetter: "node.rowIndex + 1",
                    maxWidth: 80,
                },
                { headerName: "Bank Account Number", field: "bank_account_number", minWidth: 100 },
                { headerName: "Bank Account", field: "bank_account", minWidth: 100, },
                {
                    headerName: "VND",
                    field: "closing_amount",
                    minWidth: 100,
                    cellRenderer: params => this.formatNumber(params.value)
                },
                {
                    headerName: "Foreign currency",
                    field: "foreign_currency_amount",
                    minWidth: 100,
                    cellRenderer: params => {
                        let currencySuffix = '';
                        switch (params.data.bank_account_number) {
                            case '104810220000220':
                                currencySuffix = ' (RUB)';
                                break;
                            case '1026830229':
                            case '811881188':
                                currencySuffix = ' (USD)';
                                break;
                            case '1026127242':
                            case '104310000011274':
                            case '81188118':
                                currencySuffix = ' (VND)';
                                break;
                        }
                        return this.formatNumber(params.value) + currencySuffix;
                    }
                },
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                filter: false,
                suppressMenuHide: true,
                menuTabs: ["filterMenuTab"],
            },
            dataExport: [],
        };
    },
    computed: {
        items_export() {
            return this.dataExport.map(data => {
                return {
                    "Date": data.date,
                    "Bank Account Number": data.bank_account_number,
                    "Bank Account": data.bank_account,
                    "VND": data.closing_amount,
                    "Foreign currency": data.foreign_currency_amount
                }
            });
        }

    },
    watch: {
        dialog(newVal) {
            if (newVal && this.dates && this.dates.length > 0) {
                this.selectedWeek = this.dates[0].display;
                this.fullSelectedWeek = this.dates[0];
                this.fetchData();
            }
        },
        selectedWeek(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.fullSelectedWeek = this.dates.find(date => date.display === newVal);
                if (this.fullSelectedWeek) {
                    this.fetchData();
                }
            }
        }
    },
    methods: {
        formatDateUtcToVn(dateUtc) {
            const date = new Date(dateUtc);

            const vnTimeOffset = 7 * 60;
            const localTime = new Date(date.getTime() + vnTimeOffset * 60 * 1000);

            const day = localTime.getUTCDate();
            const month = localTime.getUTCMonth() + 1;
            const year = localTime.getUTCFullYear();

            const dayString = day < 10 ? '0' + day : day;
            const monthString = month < 10 ? '0' + month : month;

            return `${dayString}/${monthString}/${year}`;
        },
        formatNumber(value) {
            if (!value && value !== 0) return '';
            return value.toLocaleString('vi-VN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },
        async fetchData() {
            if (!this.fullSelectedWeek || !this.fullSelectedWeek.start || !this.fullSelectedWeek.end) {
                return;
            }
            this.loading = true;
            const params = {
                from_date: this.fullSelectedWeek.start,
                to_date: this.fullSelectedWeek.end,
                page_size: 100000,
                page_index: 1,
                p_account_number: '112', // This might need adjustment based on your context
            };

            try {
                const response = await axios.get('https://api-misa.zigisoft.com/get_report_tien_gui_ngan_hang.php', { params });
                const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
                const data = response.data.Data[key].PageData;

                let rowData = [];
                const foreignCurrencyPromises = [];

                data.forEach(account => {
                    if (account._data && account._data.length > 0) {
                        const bankAccountNumber = account._data[0].bank_account_number;
                        const bankAccountId = this.getBankAccountId(bankAccountNumber);

                        // Define currencyType based on bankAccountNumber
                        let currencyType = 'VND'; // Default to VND
                        if (bankAccountNumber === '104810220000220') {
                            currencyType = 'RUB';
                        } else if (['1026830229', '811881188'].includes(bankAccountNumber)) {
                            currencyType = 'USD';
                        }

                        // Define selectedAccount based on bankAccountNumber
                        let selectedAccount = '1121'; // Default to 1121
                        if (bankAccountNumber === '104810220000220') {
                            selectedAccount = '112';
                        } else if (['1026830229', '811881188'].includes(bankAccountNumber)) {
                            selectedAccount = '1122';
                        }

                        const promise = this.fetchForeignCurrencyAmount(bankAccountId, currencyType, selectedAccount, this.fullSelectedWeek.start, this.fullSelectedWeek.end);
                        foreignCurrencyPromises.push(promise);
                        rowData.push({
                            date: `${this.formatDateUtcToVn(this.fullSelectedWeek.start)} - ${this.formatDateUtcToVn(this.fullSelectedWeek.end)}`,
                            bank_account_number: bankAccountNumber,
                            bank_account: account._data[0].bank_account,
                            closing_amount: account._data[0].closing_amount,
                            foreign_currency_amount: 0,
                        });
                    }
                });
                const foreignCurrencyResults = await Promise.all(foreignCurrencyPromises);
                rowData = rowData.map((row, index) => ({
                    ...row,
                    foreign_currency_amount: foreignCurrencyResults[index],
                }));

                this.rowData = rowData;
                this.dataExport = rowData;

                if (this.gridApi) {
                    this.gridApi.setRowData(this.rowData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },
        getBankAccountId(bankAccountNumber) {
            const accountIds = {
                '1026830229': 'c40b7c3f-a312-417a-bc6f-8afda2b86567',
                '811881188': 'c565db77-ad59-4b5d-a022-c6758e3788f9',
                '104810220000220': 'becb1c01-caf7-46bd-8014-d075d1d9ee5d',
                '81188118': 'cae7f019-3417-44e6-af86-e95a7d3f9ca1',
                '1026127242': 'f88aaa9f-3e17-49f2-91da-0f1ffef794aa',
                '104310000011274': 'ac6c741f-43b2-404f-8d73-f30a0668437a'
            };
            return accountIds[bankAccountNumber];
        },
        async fetchForeignCurrencyAmount(bankAccountId, currencyType, selectedAccount, from_date_utc, to_date_utc) {
            const params = {
                from_date: from_date_utc,
                to_date: to_date_utc,
                page_size: 100000,
                page_index: 1,
                loai_tien: currencyType,
                p_bank_account_id: bankAccountId,
                p_account_number: selectedAccount,
            };

            try {
                const response = await axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tien_gui_ngan_hang.php', { params });
                const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
                const data = response.data.Data[key].PageData;
                if (data && data.length > 0 && data[0]._data && data[0]._data.length > 0) {
                    return data[0]._data[0].closing_amount_oc || 0;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        reloadData() {
            this.fetchData();
        },
        openDialog() {
            this.dialog = true;
            this.fetchData();
        },
    }
}
</script>


<style>
.btn_reload {
    margin-right: 10px;
    margin-top: 4px;
    cursor: pointer;
}

.reload__icon__reload {
    filter: invert(0%) sepia(58%) saturate(2088%) hue-rotate(45deg) brightness(88%) contrast(99%);
    width: 33px;
    height: 34px;
    cursor: pointer;
}

.display-mode-select {
    margin-right: 10px;
    width: 200px;
}

.bold-text {
    font-weight: bold;
}
</style>
