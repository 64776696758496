<template>
    <DefaultLayout>
        <div class="">
            <div class="bread-crumb has-btn">
                <p class="bc-page__title">Dashboard</p>

            </div>
            <div class="card">
                <div class="page-content bg-white">
                    <SaleProfit />
                </div>
                <div class="page-content bg-white">
                    <PreSaleProfit />
                </div>
            </div>
            <div class="card">
                <div class="page-content bg-white">
                    <bankDeposit />
                </div>
                <div class="page-content bg-white">
                    <CashReport />
                </div>
            </div>
            <div class="card">
                <div class="page-content bg-white">
                    <PayableDebt />
                </div>
                <div class="page-content bg-white">
                    <ReceivableDebt />
                </div>
            </div>
            <div class="card">
                <div class="page-content bg-white">
                    <UnpaidInvoices />
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script>
import "ag-grid-enterprise"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"

import DefaultLayout from "@/layouts/default.vue"
import SaleProfit from "../components/Dashboard/SalesProfit"
import PreSaleProfit from "../components/Dashboard/PreSaleProfit"
import bankDeposit from "../components/Dashboard/bankDeposit"
import CashReport from "../components/Dashboard/CashReport"
import UnpaidInvoices from "../components/Dashboard/UnpaidInvoices"
import PayableDebt from "../components/Dashboard/PayableDebt"
import ReceivableDebt from "../components/Dashboard/ReceivableDebt"

export default {
    name: "dashboard",
    components: {
        DefaultLayout,
        SaleProfit,
        PreSaleProfit,
        bankDeposit,
        CashReport,
        UnpaidInvoices,
        PayableDebt,
        ReceivableDebt
    },
    data() {
        return {
        }
    },
    methods: {

    },
    created() {

    },
    mounted() {

    },
    beforeDestroy() {

    },
    watch: {

    },
    computed: {

    },

}
</script>

<style lang="scss">
.page-content {
    width: 100%;
    border: 1px solid #cfd4d8;
    padding-bottom: 20px;
}

.card{
    width: 100%;
    gap: 15px;
    display: flex;
    margin: 15px 0px;
}
.content {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    text-align: center;
}

.waviy {
    position: relative;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, .2));
    font-size: 60px;
}

.waviy span {
    font-family: 'Alfa Slab One', cursive;
    position: relative;
    display: inline-block;
    color: #E04038;
    text-transform: uppercase;
    animation: waviy 2.5s infinite;
    animation-delay: calc(.2s * var(--i));

}

@keyframes waviy {

    0%,
    40%,
    100% {
        transform: translateY(0)
    }

    20% {
        transform: translateY(-20px)
    }
}
</style>