var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-and-filter-header-wr zxc"},[_c('div',{staticClass:"customHeaderLabel text-and-filter-header-label",class:[
      _vm.params.labelLocation || 'label-center',
      _vm.params.column.userProvidedColDef &&
      _vm.params.column.userProvidedColDef.customHeaderClass
        ? _vm.params.column.userProvidedColDef.customHeaderClass
        : '',
    ]},[(_vm.params.haveCheckBox)?_c('input',{staticClass:"mr-1",attrs:{"type":"checkbox","name":"","id":"headerToggleCheckbox"},on:{"click":_vm.params.headerCheckBoxClick}}):_vm._e(),_vm._v(" "+_vm._s(_vm.params.displayName || _vm.params.field)+" "),(_vm.params.enableMenu)?_c('div',{ref:"menuButton",staticClass:"customHeaderMenuButton text-and-filter-header-custom-btn",class:_vm.params.headerClass,on:{"click":function($event){return _vm.onMenuClicked($event)}}},[_c('i',{staticClass:"fa",class:_vm.params.menuIcon || 'fa-bars'})]):_vm._e()]),(_vm.params.enableSorting)?_c('div',{staticClass:"customSortDownLabel",class:_vm.ascSort,on:{"click":function($event){return _vm.onSortRequested('asc', $event)}}},[_c('i',{staticClass:"fa fa-long-arrow-alt-down"})]):_vm._e(),(_vm.params.enableSorting)?_c('div',{staticClass:"customSortUpLabel",class:_vm.descSort,on:{"click":function($event){return _vm.onSortRequested('desc', $event)}}},[_c('i',{staticClass:"fa fa-long-arrow-alt-up"})]):_vm._e(),(_vm.params.enableSorting)?_c('div',{staticClass:"customSortRemoveLabel",class:_vm.noSort,on:{"click":function($event){return _vm.onSortRequested('', $event)}}},[_c('i',{staticClass:"fa fa-times"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }