<template>
  <header class="page-header">
    <div class="page-header-inner">
      <img v-if="currentTheme !== 'TRUSTANA'" src="@/assets/imgs/header-logo-txt.png" alt=""
        class="6am-header-brand pointer" height="37px" @click="navigateTo('/')" />
      <img v-else src="@/assets/imgs/icons/trustana-header-logo.svg" alt="" class="6am-header-brand pointer"
        height="37px" @click="navigateTo('/')" />
      <div class="d-flex align-items-center">
        <div class="select__otp">
          <v-autocomplete class="
              v-autocomplete__nodetails
              v-autocomplete__noshadow
              v-autocomplete__borderform
              v-autocomplete__modappend
              v-autocomplete__formheight
              v-autocomplete__template-status
            " v-model="selected" :items="options" item-text="name" @change="fetchDataOption" :return-object="true"
            :placeholder="'Choose Data'" :color="`#e04038`" dense label="" solo>
            <template v-slot:append>
              <div class="v-auto-append-icon">
                <img src="@/assets/imgs/icons/menu-down-icon.svg" alt="back-icon" height="22px" class="pointer mx-1" />
              </div>
            </template>
            <template v-slot:item="{ item, on, attrs }">
              <p class="mb-0 font-weight-regular" v-bind="attrs" v-on="on">
                {{ item.name }}
              </p>
            </template>
            <template v-slot:selection="{ item, on, attrs }">
              <p class="mb-0" v-bind="attrs" v-on="on">
                {{ item.name }}
              </p>
            </template>
          </v-autocomplete>


        </div>

        <DialogNotification />
        <div class="vertical-divider"></div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="user-auth-info d-flex align-items-center" v-bind="attrs" v-on="on">
              <div class="user_name">
                <span class="user-name">{{ userFromAuth.name }}</span>
                <span class="position">
                  <span v-if="job && typeof (job) === 'object'">Vị trí:
                    <span style="color: #E04038;">{{ job.JobPositionName }}</span>
                  </span>
                  <span v-else-if="dataMisa && typeof (dataMisa) !== 'object'" style="color: #E04038;">Misa password is
                    incorrect <a href="/user-info">Update Here</a>
                  </span>
                  <span v-else>Vị trí: </span>
                </span>
              </div>
              <img src="@/assets/imgs/user-avatar.png" alt="user-avatar" class="user-avatar" />
            </div>
          </template>
          <v-list>
            <v-list-item class="logout-dropdown">
              <div @click="navigateTo('/user-info')" style="display: flex;">
                <img src="@/assets/imgs/icons/user-icon.svg" style="cursor: pointer;" alt="" width="20px" height="20px"
                  class="user_icon mr-2">
                <v-list-item-title class="pointer">My Account</v-list-item-title>
              </div>
            </v-list-item>
            <v-list-item class="logout-dropdown">
              <div @click="confirmLogout" style="display: flex;">
                <img src="@/assets/imgs/icons/logout-icon.svg" style="cursor: pointer;" alt="" width="20px"
                  height="20px" class="user_icon mr-2">
                <v-list-item-title class="pointer" @click="confirmLogout">Logout</v-list-item-title>
              </div>
            </v-list-item>
            <!-- <v-list-item class="logout-dropdown">
              <v-list-item-title class="pointer" @click="removeToken"
                >DEV: Remove token</v-list-item-title
              >
            </v-list-item> -->
          </v-list>
        </v-menu>
      </div>
    </div>
  </header>
</template>

<script>
import DialogNotification from "@/components/DialogNotification"
import { mapGetters } from "vuex"
import routeMixins from "@/mixins/routeMixins"
import api from "@/services"
// import axios from "axios"


export default {

  components: { DialogNotification },
  mixins: [routeMixins],
  data() {
    return {
      currentTheme: process.env.VUE_APP_THEME,
      selected: null,
    }
  },
  computed: {
    ...mapGetters({
      userFromAuth: "userFromAuth",
    }),
    options() {
      return this.userFromAuth.database_misa.map(item => {
        return {
          id: item.database_id,
          name: item.database_name
        }
      })
    },
    job() {
      return this.$store.getters.job
    },
    dataMisa() {
      return this.$store.getters.dataMisa
    }
  },
  methods: {
    fetchDataOption(selectedOption) {
      this.fetchDataById(selectedOption.id)
        .then(res => {
          if (res.status > 199 && res.status < 399) {
            this.$store.commit("toast/getSuccess", `Success update data`)
            this.$router.go(0)
          } else {
            this.$store.commit("toast/getError", `Failed update data`)
          }
        })
    },
    // hàm cũ gọi api trực tiếp bị gọi lại khi chuyển router => chuyển sang store
    // async getJob() {
    //   await axios.get(
    //     `https://api-misa.zigisoft.com/get_info_me.php?email=${this.userFromAuth.email}&password=${this.userFromAuth.password_misa}`)
    //     .then((res) => {
    //       this.job = res.data.Data
    //       this.dataMisa = res.data
    //     })
    // },
    async fetchDataById(id) {
      // console.log(id);
      // console.log("f5 " + this.selected.database_id);
      const body = {
        current_database_misa_id: id,
      }

      return await api.user.updateDataMisa(body)
    },
    confirmLogout() {
      this.$store.commit("SET_LOGOUT_POPUP", true)
    },
    removeToken() {
      this.$store.commit("SET_LOGOUT_USER")
      this.$store.commit("setCurrentToken", "")
    },
    loadSelectedOption() {
      const storedSelection = this.userFromAuth.current_database_misa_id
      if (storedSelection) {
        try {
          const selected = this.userFromAuth.database_misa.filter(item => item.database_id === storedSelection)
          this.selected = {
            id: selected[0].database_id,
            name: selected[0].database_name
          }
        } catch (error) {
          console.error("Error parsing stored selected option:", error);
        }
      } else if (this.options.length > 0) {
        this.selected = {}
      }
    },
    saveSelectedOption() {
      // localStorage.setItem("selectedOption", JSON.stringify(this.selected));
    },
  },
  mounted() {
    this.fetchDataById(this.selected.id);
    // this.getJob()
  },
  created() {
    this.loadSelectedOption();
    this.$store.dispatch('getJob')
  },
  watch: {
    selected(newVal, oldVal) {
      console.log(this.selected);
      if (newVal !== oldVal) {
        this.saveSelectedOption();
      }
    },
    userFromAuth: {
      handler() {
        // Gọi lại hàm getJob() khi userFromAuth thay đổi trong store
        this.$store.dispatch('getJob')
      },
      immediate: true // Gọi ngay lập tức khi component được tạo
    }
  },
}
</script>

<style lang="scss">
.page-header {
  display: block;
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  border-bottom: 1px solid #cfd4d8;

  &-inner {
    height: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .vertical-divider {
    border-left: 1px solid #cfd4d8;
    height: 70px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .user-auth-info {
    .user-name {
      font-size: 17px;
      line-height: 27px;
      letter-spacing: 0px;
      color: #000000;
      font-weight: 500;
    }

    .user-avatar {
      margin-left: 12px;
      width: 38px;
      height: 38px;
      object-fit: contain;
      border-radius: 100%;
    }
  }

  .select__otp {
    display: flex;
    padding: 0 10px;
    margin-top: 4px;
  }

  .user_name {
    display: flex;
    flex-direction: column;
    font-weight: bold;

    .position {
      font-size: 13px;
    }
  }

  .notification {
    font-size: 10px;
  }

}
</style>
