<template>
    <div class="card_content">
        <div>
            <h3>Công nợ phải trả</h3>
            <div class="card_button">
                <div class="display_select">
                    <v-autocomplete class="
                        display-mode-select
                        v-autocomplete__nodetails
                        v-autocomplete__noshadow
                        v-autocomplete__borderform
                        v-autocomplete__modappend
                        v-autocomplete__formheight
                        v-autocomplete__template-status" v-model="displayMode" :items="displayOptions" item-text="name"
                        @change="updateCategories" :placeholder="'Choose Data'" :color="`#e04038`" dense label="" solo>
                        <template v-slot:append>
                            <div class="v-auto-append-icon">
                                <img src="@/assets/imgs/icons/menu-down-icon.svg" alt="back-icon" height="22px"
                                    class="pointer mx-1" />
                            </div>
                        </template>
                        <template v-slot:item="{ item, on, attrs }">
                            <p class="mb-0 font-weight-regular" v-bind="attrs" v-on="on">
                                {{ item.text }}
                            </p>
                        </template>
                        <template v-slot:selection="{ item, on, attrs }">
                            <p class="mb-0" v-bind="attrs" v-on="on">
                                {{ item.text }}
                            </p>
                        </template>
                    </v-autocomplete>
                    <DatePicker v-if="displayMode === 'week'" v-model="selectedDate" class="monthYearPicker"
                        type="month" format="MM-YYYY" :editable="false" id="monthYearPicker"
                        @change="updateCategories" />

                    <DatePicker v-if="displayMode === 'year'" v-model="selectedYear" class="yearPicker" type="year"
                        format="YYYY" :editable="false" id="yearPicker" @change="checkSelectedYear" />
                </div>
            </div>
        </div>
        <div v-if="isLoading" class="loading-spinner">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <apexchart v-else type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { VAutocomplete, VProgressCircular } from 'vuetify/lib';

export default {
    components: {
        apexchart: VueApexCharts,
        DatePicker,
        VAutocomplete,
        VProgressCircular,
    },
    data() {
        return {
            revenue: 0,
            cost: 0,
            profit: 0,
            selectedDate: new Date(),
            selectedYear: new Date(), 
            displayMode: 'week',
            displayOptions: [
                { text: 'Theo Tuần', value: 'week' },
                { text: 'Theo Năm', value: 'year' },
            ],
            isLoading: false,
            range: {},
            series: [
                {
                    name: 'Lợi nhuận',
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'bar',
                    zoom: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'straight',
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5,
                    },
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Đơn vị (VNĐ)',
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
                        },
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
                        },
                    },
                },
            },
        };
    },
    watch: {
        displayMode(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.resetSelections();
                this.updateCategories();
            }
        },
        selectedDate() {
            this.checkSelectedDate();
        },
        selectedYear() {
            this.checkSelectedYear();
        },
    },
    methods: {

        vietnamToUTCFormatted(date) {
            const dateUTC = new Date(date.getTime() - (7 * 60 * 60 * 1000));
            const localISO = date.toISOString();
            const utcISO = dateUTC.toISOString();
            return utcISO.split('T')[0] + 'T' + localISO.split('T')[1];
        },
        calculateProfit(revenue, cost) {
            return revenue - cost;
        },
        resetSelections() {
            const currentDate = new Date();
            this.selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.selectedYear = new Date(currentDate.getFullYear(), 0, 1);
        },
        checkSelectedDate() {
            const currentDate = new Date();
            const selectedDate = new Date(this.selectedDate);

            if (selectedDate > currentDate) {
                this.$store.commit('toast/getError', `You cannot select a month and year greater than the current month and year.`);
                this.selectedDate = currentDate;
            } else {
                this.updateCategories();
            }
        },
        checkSelectedYear() {
            const currentYear = new Date().getFullYear();
            if (this.selectedYear.getFullYear() > currentYear) {
                this.$store.commit('toast/getError', `You cannot select a year greater than the current year.`);
                this.selectedYear = new Date(currentYear, 0, 1);
            } else {
                this.updateCategories();
            }
        },
        updateCategories() {
            this.isLoading = true; 
            if (this.displayMode === 'week') {
                this.updateMonthCategories();
            } else if (this.displayMode === 'year') {
                this.updateYearCategories();
            }
        },
        updateMonthCategories() {
            const currentDate = new Date();
            const currentDay = currentDate.getDate();
            const selectedMonth = this.selectedDate.getMonth();
            const selectedYear = this.selectedDate.getFullYear();
            const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
            const weeks = [];
            const weekRanges = [];

            for (let i = 1; i <= daysInMonth; i += 7) {
                const startDay = new Date(selectedYear, selectedMonth, i);
                const endDay = new Date(selectedYear, selectedMonth, Math.min(i + 6, daysInMonth));

                const weekOfYear = this.getWeekOfYear(startDay);

                weeks.push(`Tuần thứ ${weekOfYear}`);
                weekRanges.push({
                    start: this.vietnamToUTCFormatted(startDay),
                    end: this.vietnamToUTCFormatted(endDay),
                });

                if (endDay.getDate() >= currentDay && selectedMonth === currentDate.getMonth() && selectedYear === currentDate.getFullYear()) {
                    break;
                }
            }

            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: weeks,
                },
            };
            this.range = weekRanges;
            this.updateSeriesData(weekRanges);
        },

        getWeekOfYear(date) {
            const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
            const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
            return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        },
        updateYearCategories() {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const selectedYear = this.selectedYear.getFullYear();
            const months = [];
            const monthRanges = [];

            const monthsToShow = selectedYear === currentYear ? currentMonth + 1 : 12;
            for (let i = 0; i < monthsToShow; i++) {
                const startDay = new Date(selectedYear, i, 1);
                const endDay = new Date(selectedYear, i + 1, 0);
                months.push(`Tháng ${i + 1}`);
                monthRanges.push({
                    start: this.vietnamToUTCFormatted(startDay),
                    end: this.vietnamToUTCFormatted(endDay),
                });
            }

            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: months,
                },
            };
            this.range = monthRanges;
            this.updateSeriesData(monthRanges);
        },
        async fetchData(range) {
            const from_date_utc = range.start;
            const to_date_utc = range.end;

            const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
            
            const Response = await axios.get('https://api-misa.zigisoft.com/get_report_cong_no_phai_tra.php', {
                params: {
                    from_date: from_date_utc,
                    to_date: to_date_utc,
                    page_size: 100000,
                    page_index: 1
                }
            });

            const pageData = Response.data.Data[key].SummaryData.close_credit_amount;

            return {
                cash: pageData,
                date: range.start
            };
        },
        async updateSeriesData(ranges) {
            const data = [];
            for (const range of ranges) {
                const { cash } = await this.fetchData(range);
                data.push(cash);
            }
            this.series = [{
                name: 'Công nợ phải trả',
                data: data,
            }];
            this.isLoading = false; 
        },
    },
    mounted() {
        this.updateCategories();
    },
};
</script>

<style scoped>
.card_content {
    padding: 15px;
}

.card_button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.display-mode-select {
    margin-right: 10px;
    width: 200px;
}

.monthYearPicker,
.yearPicker {
    margin-right: 10px;
}

.btn-normal-text {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-normal-text:hover {
    background-color: #0056b3;
}

.display-mode-select {
    width: 190px;
}

.display_select {
    display: flex;
    gap: 15px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
}
</style>
