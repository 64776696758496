<template>
  <v-card>
    <v-card-title>
      <h3>Các hóa đơn chưa thanh toán</h3>
      <v-spacer></v-spacer>
      <v-btn icon @click="dialog = false">
        <v-icon icon="$mdi-close"></v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div style="position: relative; width: 100%;">
        <div class="filter-block py-3 px-3" style="width: 100%;">
          <div class="btn_reload" v-tooltip="'Reload data table'">
            <img src="@/assets/imgs/icons/sync-icon.svg" alt="company-icon" class="reload__icon__reload"
              v-on:click="reloadData" />
          </div>
          <div class="btn_excel" v-tooltip="'Export to Excel'">
            <export-excel :data="items_export" :name="`Report Detail Unpaid Invoices`">
              <img src="@/assets/imgs/icons/excel-icon.svg" alt="company-icon" class="excel__icon" />
            </export-excel>
          </div>
        </div>
        <ag-grid-vue id="myGridSidebar" style="width: 100%; height: 600px;" class="ag-theme-alpine"
          :masterDetail="masterDetail" :columnDefs="columnDefs" :rowData="rowData" :defaultColDef="defaultColDef"
          :pagination="true" :paginationPageSize="12" :pivotMode="false" :rowSelection="'multiple'"
          :detailCellRendererParams="detailCellRendererParams" @grid-ready="onGridReady">
        </ag-grid-vue>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import axios from 'axios';

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      rowData: [],
      masterDetail: true,
      selectedDate: new Date(),
      range: {},
      columnDefs: [
        {
          headerName: "STT",
          valueGetter: "node.rowIndex + 1",
          maxWidth: 80,
        },
        { headerName: "Invoice No", field: "inv_no", cellRenderer: 'agGroupCellRenderer' },
        {
          headerName: "Date",
          field: "inv_date",
          valueFormatter: function (params) {
            const date = new Date(params.value);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
          },
          maxWidth: 200,
        },
        { headerName: "Account Name", field: "account_object_name" },
        {
          field: "total_amount",
          headerName: "Total Amount",
          cellRenderer: function (params) {
            let parts = Number(params.value).toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return Number(parts.join(',')) < 0 ? `<span style="color: #D03E47;">${parts.join(',')}</span>` : `<span class='text-green'>${parts.join(',')}</span>`
          }
        },
      ],
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
        resizable: true,
      },
      dataExport: [],
      detailData: {},
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: "STT",
              valueGetter: "node.rowIndex + 1",
              maxWidth: 80,
            },
            { headerName: "Mã hàng ", field: "inventory_item_code" },
            { headerName: "Tên dịch vụ", field: "description" },
            { headerName: "Đối tượng THCP", field: "job_code" },
          ],
          defaultColDef: {
            flex: 1,
            minWidth: 150,
          },
        },
        getDetailRowData: function (params) {
          params.successCallback(this.detailData[params.data.inv_no] || []);
        }.bind(this)
      }
    };
  },
  computed: {
    items_export() {
      return this.dataExport.map(data => {
        let job_codes = this.detailData[data.inv_no]?.map(detail => detail.job_code).filter(code => code != null);
        let uniqueJobCodes = [...new Set(job_codes)];
        let jobCodeColumns = uniqueJobCodes.reduce((obj, jobCode, index) => {
          obj[`THCP${index + 1}`] = jobCode;
          return obj;
        }, {});
        let formattedDate = new Date(data.inv_date).toLocaleDateString('vi-VN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
        return {
          "Invoice No": data.inv_no,
          "Date": formattedDate,
          "Account Name": data.account_object_name,
          "Total Amount": data.total_amount,
          ...jobCodeColumns
        }
      });
    }
  },
  methods: {
    vietnamToUTCFormatted(date) {
      const dateUTC = new Date(date.getTime() - (7 * 60 * 60 * 1000));
      const localISO = date.toISOString();
      const utcISO = dateUTC.toISOString();
      return utcISO.split('T')[0] + 'T' + localISO.split('T')[1];
    },
    async fetchData() {
      this.gridApi.showLoadingOverlay();
      const startDate = new Date('2024-01-01');
      const endDate = new Date();

      let range = {
        start: this.vietnamToUTCFormatted(startDate),
        end: this.vietnamToUTCFormatted(endDate),
      };

      axios.get('https://api-misa.zigisoft.com/get_khach_hang.php', {
        params: {
          from: range.start,
          to: range.end
        }
      })
        .then(response => {
          const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
          const allData = response.data.Data[key].PageData;
          const filteredData = allData.filter(item => item.paid_type === 0);
          this.dataExport = filteredData;
          this.rowData = filteredData;
          this.fetchDetailData(filteredData);
          this.gridApi.hideOverlay();
        })
        .catch(error => {
          console.error("Error fetching data:", error);
          this.gridApi.hideOverlay();
        });
    },
    async fetchDetailData(invoices) {
      let requests = invoices.map(invoice => {
        return axios.get(`https://api-misa.zigisoft.com/get_chi_tiet_khach_hang.php?refid=${invoice.refid}`)
          .then(response => {
            const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
            this.$set(this.detailData, invoice.inv_no, response.data.Data[key].PageData);
          })
          .catch(error => {
            console.error(`Error fetching details for invoice ${invoice.inv_no}:`, error);
          });
      });

      await Promise.all(requests);
    },
    reloadData() {
      this.fetchData();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.fetchData();
    }
  }
}
</script>

<style>
.btn_reload {
  margin-right: 10px;
  margin-top: 4px;
  cursor: pointer;
}

.reload__icon__reload {
  filter: invert(0%) sepia(58%) saturate(2088%) hue-rotate(45deg) brightness(88%) contrast(99%);
  width: 33px;
  height: 34px;
  cursor: pointer;
}

.display-mode-select {
  margin-right: 10px;
  width: 200px;
}
</style>
