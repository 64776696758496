import { render, staticRenderFns } from "./ConfigAirport.vue?vue&type=template&id=4841ace2&scoped=true"
import script from "./ConfigAirport.vue?vue&type=script&lang=js"
export * from "./ConfigAirport.vue?vue&type=script&lang=js"
import style0 from "./ConfigAirport.vue?vue&type=style&index=0&id=4841ace2&prod&lang=scss&scoped=true"
import style1 from "./ConfigAirport.vue?vue&type=style&index=1&id=4841ace2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4841ace2",
  null
  
)

export default component.exports