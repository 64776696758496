const roleRepo = (axios) => {
    return {
        getListDataUnpaid(payload) {
            console.log(payload)
            return axios
                .get(`https://api-misa.zigisoft.com/get_report_loi_nhuan_ban_hang.php`, {
                    params: payload,
                })
                .then((res) => {
                    return res
                })
                .catch((err) => {
                    return err.response
                })
        },
        
    }
}

export default roleRepo
