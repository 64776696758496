<template>
    <div class="card_content">
        <div>
            <h3>Lợi nhuận bán hàng trước thuế</h3>
            <div class="card_button">
                <div class="display_select">
                    <v-autocomplete class="
                        display-mode-select
                        v-autocomplete__nodetails
                        v-autocomplete__noshadow
                        v-autocomplete__borderform
                        v-autocomplete__modappend
                        v-autocomplete__formheight
                        v-autocomplete__template-status" v-model="displayMode" :items="displayOptions" item-text="text"
                        @change="resetSelections" :placeholder="'Choose Data'" :color="`#e04038`" dense label="" solo>
                        <template v-slot:append>
                            <div class="v-auto-append-icon">
                                <img src="@/assets/imgs/icons/menu-down-icon.svg" alt="back-icon" height="22px"
                                    class="pointer mx-1" />
                            </div>
                        </template>
                        <template v-slot:item="{ item, on, attrs }">
                            <p class="mb-0 font-weight-regular" v-bind="attrs" v-on="on">
                                {{ item.text }}
                            </p>
                        </template>
                        <template v-slot:selection="{ item, on, attrs }">
                            <p class="mb-0" v-bind="attrs" v-on="on">
                                {{ item.text }}
                            </p>
                        </template>
                    </v-autocomplete>
                    <DatePicker v-if="displayMode === 'quarter'" v-model="selectedYear" class="yearPicker" type="year"
                        format="YYYY" :editable="false" id="yearPicker" @change="updateCategories" />
                    <DatePicker v-if="displayMode === 'year'" v-model="selectedYear" class="yearPicker" type="year"
                        format="YYYY" :editable="false" id="yearPicker" @change="updateCategories" />
                    <DatePicker v-if="displayMode === 'custom'" v-model="selectedDateRange" type="month" range
                        format="MM-YYYY" :editable="false" id="customPicker" @change="updateCategories" />
                </div>

                <v-btn depressed :color="returnThemeColor()" class="btn-normal-text text-white" @click="showReport">
                    View Report
                </v-btn>
                <ModalPreSaleProfit ref="reportModal" :profit="profit" :range="range" :dates="dates" />
            </div>
        </div>
        <div v-if="isLoading" class="loading-spinner">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <apexchart v-else type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { VAutocomplete, VBtn, VProgressCircular } from 'vuetify/lib';
import ModalPreSaleProfit from '@/components/Dashboard/ModalPreSaleProfit.vue';

export default {
    components: {
        apexchart: VueApexCharts,
        DatePicker,
        VAutocomplete,
        VBtn,
        VProgressCircular,
        ModalPreSaleProfit
    },
    data() {
        return {
            revenue: 0,
            cost: 0,
            profit: 0,
            selectedDateRange: [new Date(), new Date()],
            selectedYear: new Date(),
            displayMode: 'quarter',
            displayOptions: [
                { text: 'Theo Quý', value: 'quarter' },
                { text: 'Theo Năm', value: 'year' },
                { text: 'Tùy Chỉnh', value: 'custom' },
            ],
            isLoading: false,
            range: {},
            dates: [],
            series: [
                {
                    name: 'Lợi nhuận',
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'straight',
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5,
                    },
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Đơn vị (VNĐ)',
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
                        },
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
                        },
                    },
                },
            },
        };
    },
    watch: {
        displayMode(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.resetSelections();
            }
        },
        selectedDateRange() {
            this.updateCategories();
        },
        selectedYear() {
            this.updateCategories();
        },
    },
    methods: {
        showReport() {
            this.$refs.reportModal.openDialog();
        },
        vietnamToUTCFormatted(date) {
            const dateUTC = new Date(date.getTime() - (7 * 60 * 60 * 1000));
            const localISO = date.toISOString();
            const utcISO = dateUTC.toISOString();
            return utcISO.split('T')[0] + 'T' + localISO.split('T')[1];
        },
        calculateProfit(profit, key_641, key_642, key_711, key_811, key_515, key_635) {
            return profit - key_641 - key_642 + (key_711 - key_811) + (key_515 - key_635);
        },
        resetSelections() {
            const currentDate = new Date();
            this.selectedDateRange = [new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 6), currentDate];
            this.selectedYear = new Date(currentDate.getFullYear(), 0, 1);
        },
        updateCategories() {
            this.isLoading = true;
            if (this.displayMode === 'quarter') {
                this.updateQuarterCategories();
            } else if (this.displayMode === 'year') {
                this.updateYearCategories();
            } else if (this.displayMode === 'custom') {
                this.updateCustomCategories();
            }
        },
        updateQuarterCategories() {
            const selectedYear = this.selectedYear.getFullYear();
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth();
            const currentQuarter = Math.floor(currentMonth / 3) + 1;
            const endQuarter = selectedYear === currentYear ? currentQuarter : 4;
            const quarters = [];
            const quarterRanges = [];
            const quarterDates = [];

            for (let i = 1; i <= endQuarter; i++) {
                const startMonth = (i - 1) * 3;
                const endMonth = startMonth + 2;
                const startDay = new Date(selectedYear, startMonth, 1);
                const endDay = new Date(selectedYear, endMonth + 1, 0);
                quarters.push(`Quý ${i}`);
                quarterRanges.push({
                    start: this.vietnamToUTCFormatted(startDay),
                    end: this.vietnamToUTCFormatted(endDay),
                });

                quarterDates.push({
                    display: `Quý ${i}`,
                    start: this.vietnamToUTCFormatted(startDay),
                    end: this.vietnamToUTCFormatted(endDay),
                });
            }

            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: quarters,
                },
            };
            this.range = quarterRanges;
            this.dates = quarterDates;
            this.updateSeriesData(quarterRanges);
        },
        updateYearCategories() {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const selectedYear = this.selectedYear.getFullYear();
            const months = [];
            const monthRanges = [];
            const monthDates = [];

            const monthsToShow = selectedYear === currentYear ? currentMonth + 1 : 12;
            for (let i = 0; i < monthsToShow; i++) {
                const startDay = new Date(selectedYear, i, 1);
                const endDay = new Date(selectedYear, i + 1, 0);
                months.push(`Tháng ${i + 1}`);
                monthRanges.push({
                    start: this.vietnamToUTCFormatted(startDay),
                    end: this.vietnamToUTCFormatted(endDay),
                });

                monthDates.push({
                    display: `Tháng ${i + 1}`,
                    start: this.vietnamToUTCFormatted(startDay),
                    end: this.vietnamToUTCFormatted(endDay),
                });
            }

            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: months,
                },
            };
            this.range = monthRanges;
            this.dates = monthDates;
            this.updateSeriesData(monthRanges);
        },
        updateCustomCategories() {
            const startMonth = new Date(this.selectedDateRange[0]);
            const endMonth = new Date(this.selectedDateRange[1]);
            const months = [];
            const monthRanges = [];
            const monthDates = [];

            const monthDiff = endMonth.getMonth() - startMonth.getMonth() + 12 * (endMonth.getFullYear() - startMonth.getFullYear());

            for (let i = 0; i <= monthDiff; i++) {
                const month = new Date(startMonth.getFullYear(), startMonth.getMonth() + i, 1);
                const endDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
                months.push(`${month.getMonth() + 1}-${month.getFullYear()}`);
                monthRanges.push({
                    start: this.vietnamToUTCFormatted(new Date(month.getFullYear(), month.getMonth(), 1)),
                    end: this.vietnamToUTCFormatted(endDay),
                });

                monthDates.push({
                    display: `${month.getMonth() + 1}-${month.getFullYear()}`,
                    start: this.vietnamToUTCFormatted(new Date(month.getFullYear(), month.getMonth(), 1)),
                    end: this.vietnamToUTCFormatted(endDay),
                });
            }

            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: months,
                },
            };
            this.range = monthRanges;
            this.dates = monthDates;
            this.updateSeriesData(monthRanges);
        },

        async fetchData(range) {
            const from_date_utc = range.start;
            const to_date_utc = range.end;

            const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';


            const Response = await axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tai_khoan.php', {
                params: {
                    from_date: from_date_utc,
                    to_date: to_date_utc,
                    account_number: '5113,632',
                    page_size: 1000000,
                    page_index: 1
                }
            });
            const pageData = Response.data.Data[key].PageData;

            const jobData = {};
            for (let account of pageData) {
                const accountKey = account._key;

                if (!account._data) {
                    console.error('PageData is missing for account:', accountKey);
                    continue;
                }

                for (let item of account._data) {
                    const jobCode = item.job_code;
                    if (!jobCode) continue;

                    if (!jobData[jobCode]) {
                        jobData[jobCode] = { revenue: 0, cost: 0, cp_com: 0, cp_fct: 0, key_641: 0, key_642: 0, key_711: 0, key_811: 0, key_515: 0, key_635: 0 };
                    }

                    if (accountKey === '5113') {
                        jobData[jobCode].revenue += parseFloat(item.credit_amount || 0);
                    } else if (accountKey === '632') {

                        if (item.expense_item_code) {
                            if (item.expense_item_code === 'CP.COM') {
                                jobData[jobCode].cp_com += parseFloat(item.debit_amount || 0);
                            } else if (item.expense_item_code.includes('CP.FCT')) {
                                jobData[jobCode].cp_fct += parseFloat(item.debit_amount || 0);
                            } else {
                                jobData[jobCode].cost += parseFloat(item.debit_amount || 0);
                            }
                        } else {
                            jobData[jobCode].cost += parseFloat(item.debit_amount || 0);
                        }
                    }
                }
            }
            const Response641 = await axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tai_khoan.php', {
                params: {
                    from_date: from_date_utc,
                    to_date: to_date_utc,
                    account_number: '641,642,711,811,515,635',
                    page_size: 1000000,
                    page_index: 1
                }
            });
            const pageData641 = Response641.data.Data[key].PageData;
            const keyAmounts = {
                key_641: 0,
                key_642: 0,
                key_711: 0,
                key_811: 0,
                key_515: 0,
                key_635: 0
            };

            for (let account of pageData641) {
                for (let item of account._data) {
                    const account_number = item.account_number;
                    const description = item.description;
                    const debit_amount = parseFloat(item.debit_amount || 0);
                    const credit_amount = parseFloat(item.credit_amount || 0);

                    if (description === "Cộng") {
                        if (account_number === "641") {
                            keyAmounts.key_641 = debit_amount;
                        } else if (account_number === "642") {
                            keyAmounts.key_642 = debit_amount;
                        } else if (account_number === "711") {
                            keyAmounts.key_711 = credit_amount;
                        } else if (account_number === "811") {
                            keyAmounts.key_811 = debit_amount;
                        } else if (account_number === "515") {
                            keyAmounts.key_515 = credit_amount;
                        } else if (account_number === "635") {
                            keyAmounts.key_635 = debit_amount;
                        }
                    }
                }
            }


            const formattedData = Object.entries(jobData).map(([jobCode, data]) => {
                const profit = (data.revenue) - (data.cost + data.cp_com + data.cp_fct);
                return {
                    job_code: jobCode,
                    profit: profit,
                };
            });
            const totalProfit = formattedData.reduce((total, { profit }) => total + profit, 0);
            const dataAll = {
                profit: totalProfit,
                ...keyAmounts
            }
            return dataAll;
        },

        async updateSeriesData(ranges) {
            const seriesData = [];
            const categories = [];

            for (const range of ranges) {
                const data = await this.fetchData(range);
                const totalProfit = this.calculateProfit(data.profit, data.key_641, data.key_642, data.key_711, data.key_811, data.key_515, data.key_635);

                seriesData.push({
                    profit: totalProfit
                });

                if (this.displayMode === 'quarter') {
                    categories.push(`Quý ${categories.length + 1}`);
                } else if (this.displayMode === 'year') {
                    const month = new Date(range.start).getMonth() + 1;
                    categories.push(`Tháng ${month}`);
                } else if (this.displayMode === 'custom') {
                    categories.push(`Tháng ${new Date(range.start).getMonth() + 1}`);
                }
            }

            this.series = [{
                name: 'Lợi nhuận',
                data: seriesData.map(data => data.profit),
            }];

            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: categories,
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
                        },
                        title: {
                            formatter: function (seriesName, opts) {
                                return categories[opts.dataPointIndex];
                            }
                        },
                        marker: {
                            show: true,
                        },
                        fixed: {
                            enabled: true,
                            position: 'topRight',
                        },
                    }
                }
            };
            this.isLoading = false;
        },
    },
    mounted() {
        this.updateCategories();
    },
};
</script>

<style scoped>
.card_content {
    padding: 15px;
}

.card_button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.display-mode-select {
    margin-right: 10px;
    width: 200px;
}

.monthYearPicker,
.yearPicker,
.customPicker {
    margin-right: 10px;
}

.btn-normal-text {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-normal-text:hover {
    background-color: #0056b3;
}

.display-mode-select {
    width: 190px;
}

.display_select {
    display: flex;
    gap: 15px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
}
</style>
