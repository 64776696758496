<template>
    <div class="card_content">
        <div>
            <h3>Báo cáo tiền gửi</h3>
            <div class="card_button">
                <div class="display_select">
                    <v-autocomplete
                        class="display-mode-select v-autocomplete__nodetails v-autocomplete__noshadow v-autocomplete__borderform v-autocomplete__modappend v-autocomplete__formheight v-autocomplete__template-status"
                        v-model="displayMode" :items="displayOptions" item-text="name" @change="updateCategories"
                        :placeholder="'Choose Data'" :color="`#e04038`" dense label="" solo>
                        <template v-slot:append>
                            <div class="v-auto-append-icon">
                                <img src="@/assets/imgs/icons/menu-down-icon.svg" alt="back-icon" height="22px"
                                    class="pointer mx-1" />
                            </div>
                        </template>
                        <template v-slot:item="{ item, on, attrs }">
                            <p class="mb-0 font-weight-regular" v-bind="attrs" v-on="on">
                                {{ item.text }}
                            </p>
                        </template>
                        <template v-slot:selection="{ item, on, attrs }">
                            <p class="mb-0" v-bind="attrs" v-on="on">
                                {{ item.text }}
                            </p>
                        </template>
                    </v-autocomplete>
                    <DatePicker v-if="displayMode === 'week' || displayMode === 'day'" v-model="selectedDate"
                        class="monthYearPicker" type="month" format="MM-YYYY" :editable="false" id="monthYearPicker"
                        @change="calculateWeeksInMonth" />
                    <DatePicker v-if="displayMode === 'year'" v-model="selectedYear" class="yearPicker" type="year"
                        format="YYYY" :editable="false" id="yearPicker" @change="checkSelectedYear" />

                </div>
                <v-btn depressed :color="returnThemeColor()"
                    class="btn-normal-text text-white" @click="showReport">
                    View Report
                </v-btn>
                <ModalBankDeposit ref="reportModal" :selectedCurrency="selectedCurrency" :dates="dates" />
            </div>
        </div>
        <div class="select_two">
            <v-autocomplete
                class="currency-select v-autocomplete__nodetails v-autocomplete__noshadow v-autocomplete__borderform v-autocomplete__modappend v-autocomplete__formheight v-autocomplete__template-status"
                v-model="selectedCurrency" :items="currencyOptions" item-text="text" item-value="value"
                @change="updateCurrency" :placeholder="'Select Currency'" :color="`#e04038`" dense solo>
                <template v-slot:append>
                    <div class="v-auto-append-icon">
                        <img src="@/assets/imgs/icons/menu-down-icon.svg" alt="back-icon" height="22px"
                            class="pointer mx-1" />
                    </div>
                </template>
                <template v-slot:item="{ item, on, attrs }">
                    <p class="mb-0 font-weight-regular" v-bind="attrs" v-on="on">
                        {{ item.text }}
                    </p>
                </template>
                <template v-slot:selection="{ item, on, attrs }">
                    <p class="mb-0" v-bind="attrs" v-on="on">
                        {{ item.text }}
                    </p>
                </template>
            </v-autocomplete>
            <v-autocomplete
                class="week-select v-autocomplete__nodetails v-autocomplete__noshadow v-autocomplete__borderform v-autocomplete__modappend v-autocomplete__formheight v-autocomplete__template-status"
                v-if="displayMode === 'day'" v-model="selectedWeek" :items="weeksInSelectedMonth" item-text="text"
                item-value="value" label="Chọn tuần" solo dense @change="handleWeekChange">
                <template v-slot:append>
                    <div class="v-auto-append-icon">
                        <img src="@/assets/imgs/icons/menu-down-icon.svg" alt="back-icon" height="22px"
                            class="pointer mx-1" />
                    </div>
                </template>
                <template v-slot:item="{ item, on, attrs }">
                    <p class="mb-0 font-weight-regular" v-bind="attrs" v-on="on">
                        {{ item.text }}
                    </p>
                </template>
                <template v-slot:selection="{ item, on, attrs }">
                    <p class="mb-0" v-bind="attrs" v-on="on">
                        {{ item.text }}
                    </p>
                </template>
            </v-autocomplete>
            <div class="btn_excel" v-tooltip="'Export to Excel'">
                <export-excel :data="items_export" :name="`Report Detail Bank Deposit`">
                    <img src="@/assets/imgs/icons/excel-icon.svg" alt="company-icon" class="excel__icon" />
                </export-excel>
            </div>
        </div>
        <div v-if="isLoading" class="loading-spinner">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <apexchart v-else type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { VAutocomplete, VProgressCircular, VBtn } from 'vuetify/lib';
import ModalBankDeposit from '@/components/Dashboard/ModalBankDeposit.vue';

export default {
    components: {
        apexchart: VueApexCharts,
        DatePicker,
        VAutocomplete,
        VProgressCircular,
        VBtn,
        ModalBankDeposit,
    },
    data() {
        return {
            revenue: 0,
            cost: 0,
            profit: 0,
            dates: [],
            selectedDate: new Date(),
            selectedYear: new Date(),
            selectedWeek: null,
            displayMode: 'week',
            displayOptions: [
                { text: 'Theo Ngày', value: 'day' },
                { text: 'Theo Tuần', value: 'week' },
                { text: 'Theo Năm', value: 'year' },
            ],
            weeksInSelectedMonth: [],
            selectedCurrency: '1121',
            currencyOptions: [
                { text: 'Tiền Việt (VND)', value: '1121', type: 'VND' },
                { text: 'Đô la Mỹ (USD)', value: '1122', type: 'USD' },
                { text: 'Rúp Nga (RUB)', value: '112', type: 'RUB' },
            ],
            isLoading: false,
            range: {},
            series: [
                {
                    name: 'Lợi nhuận',
                    data: [],
                },
            ],
            exportData: [], // Added for export data

            chartOptions: {
                chart: {
                    height: 350,
                    type: 'bar',
                    zoom: {
                        enabled: true,
                    },
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'straight',
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5,
                    },
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Đơn vị (VNĐ)',
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
                        },
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
                        },
                    },
                },
            },
        };
    },
    watch: {
        displayMode(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.resetSelections();
                this.updateCategories();
            }
        },
        selectedDate() {
            this.checkSelectedDate();
        },
        selectedYear() {
            this.checkSelectedYear();
        },
        selectedWeek(newVal, oldVal) {
            if (newVal !== oldVal && this.displayMode === 'day') {
                this.updateDayCategories();
            }
        },
    },
    computed: {
        items_export() {
            return this.exportData.map(data => {

                return {
                    "Date": data.date,
                    "Bank Account Number": data.bank_account_number,
                    "VND": data.closing_amount,
                    "Foreign currency": data.foreign_currency_amount
                }
            });
        }
    },
    methods: {
        showReport() {
            this.$refs.reportModal.openDialog();
        },
        vietnamToUTCFormatted(date) {
            const dateUTC = new Date(date.getTime() - (7 * 60 * 60 * 1000));
            const localISO = date.toISOString();
            const utcISO = dateUTC.toISOString();
            return utcISO.split('T')[0] + 'T' + localISO.split('T')[1];
        },
        formatDateUtcToVn(dateUtc) {
            const date = new Date(dateUtc);

            const vnTimeOffset = 7 * 60;
            const localTime = new Date(date.getTime() + vnTimeOffset * 60 * 1000);

            const day = localTime.getUTCDate();
            const month = localTime.getUTCMonth() + 1;
            const year = localTime.getUTCFullYear();

            const dayString = day < 10 ? '0' + day : day;
            const monthString = month < 10 ? '0' + month : month;

            return `${dayString}/${monthString}/${year}`;
        },
        calculateProfit(revenue, cost) {
            return revenue - cost;
        },
        resetSelections() {
            const currentDate = new Date();
            this.selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.selectedYear = new Date(currentDate.getFullYear(), 0, 1);
            this.selectedWeek = null;
            if (this.displayMode === 'day') {
                this.calculateWeeksInMonth();
            }
        },
        checkSelectedDate() {
            const currentDate = new Date();
            const selectedDate = new Date(this.selectedDate);

            if (selectedDate > currentDate) {
                this.$store.commit('toast/getError', `You cannot select a month and year greater than the current month and year.`);
                this.selectedDate = currentDate;
            } else {
                this.updateCategories();
            }
        },
        checkSelectedYear() {
            const currentYear = new Date().getFullYear();
            if (this.selectedYear.getFullYear() > currentYear) {
                this.$store.commit('toast/getError', `You cannot select a year greater than the current year.`);
                this.selectedYear = new Date(currentYear, 0, 1);
            } else {
                this.updateCategories();
            }
        },
        updateCategories() {
            this.isLoading = true;
            if (this.displayMode === 'week') {
                this.updateMonthCategories();
            } else if (this.displayMode === 'year') {
                this.updateYearCategories();
            } else if (this.displayMode === 'day') {
                this.updateDayCategories();
            }
        },
        updateDayCategories() {
            if (!this.selectedWeek) return;

            const currentDate = new Date();
            const startDay = new Date(this.selectedWeek.start);
            const endDay = new Date(this.selectedWeek.end);
            const dayRanges = [];
            const categories = [];
            const dayRangesModal = [];
            for (let d = startDay; d <= endDay && d <= currentDate; d.setDate(d.getDate() + 1)) {
                dayRanges.push({
                    start: this.vietnamToUTCFormatted(new Date(d)),
                    end: this.vietnamToUTCFormatted(new Date(d)),
                });
                dayRangesModal.push({
                    display: `Ngày ${d.getDate()}`,
                    start: this.vietnamToUTCFormatted(new Date(d)),
                    end: this.vietnamToUTCFormatted(new Date(d)),
                })
                categories.push(`Ngày ${d.getDate()}`);
            }

            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: categories,
                },
            };
            this.range = dayRanges;
            this.dates = dayRangesModal;
            this.updateSeriesData(dayRanges);
        },
        updateMonthCategories() {
            const currentDate = new Date();
            const currentDay = currentDate.getDate();
            const selectedMonth = this.selectedDate.getMonth();
            const selectedYear = this.selectedDate.getFullYear();
            const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
            const weeks = [];
            const weekRanges = [];
            const weekDates = [];

            for (let i = 1; i <= daysInMonth; i += 7) {
                const startDay = new Date(selectedYear, selectedMonth, i);
                const endDay = new Date(selectedYear, selectedMonth, Math.min(i + 6, daysInMonth));

                const weekOfYear = this.getWeekOfYear(startDay);

                weeks.push(`Tuần thứ ${weekOfYear}`);
                weekRanges.push({
                    start: this.vietnamToUTCFormatted(startDay),
                    end: this.vietnamToUTCFormatted(endDay),
                });

                weekDates.push({
                    display: `Tuần ${weekOfYear}`,
                    start: this.vietnamToUTCFormatted(startDay),
                    end: this.vietnamToUTCFormatted(endDay),
                });

                if (endDay.getDate() >= currentDay && selectedMonth === currentDate.getMonth() && selectedYear === currentDate.getFullYear()) {
                    break;
                }
            }

            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: weeks,
                },
            };
            this.range = weekRanges;
            this.dates = weekDates;
            this.updateSeriesData(weekRanges);
        },

        getWeekOfYear(date) {
            const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
            const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
            return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        },
        updateYearCategories() {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const selectedYear = this.selectedYear.getFullYear();
            const months = [];
            const monthRanges = [];
            const monthDates = [];

            const monthsToShow = selectedYear === currentYear ? currentMonth + 1 : 12;
            for (let i = 0; i < monthsToShow; i++) {
                const startDay = new Date(selectedYear, i, 1);
                const endDay = new Date(selectedYear, i + 1, 0);
                months.push(`Tháng ${i + 1}`);
                monthRanges.push({
                    start: this.vietnamToUTCFormatted(startDay),
                    end: this.vietnamToUTCFormatted(endDay),
                });

                monthDates.push({
                    display: `Tháng ${i + 1}`,
                    start: this.vietnamToUTCFormatted(startDay),
                    end: this.vietnamToUTCFormatted(endDay),
                });
            }

            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: months,
                },
            };
            this.range = monthRanges;
            this.dates = monthDates;
            this.updateSeriesData(monthRanges);
        },
        updateCurrency() {
            this.isLoading = true;
            this.updateSeriesData(this.range)
                .then(() => {
                    this.isLoading = false;
                })
                .catch(error => {
                    console.error("Failed to update data:", error);
                    this.isLoading = false;
                });
        },
        calculateWeeksInMonth() {
            this.isLoading = true;
            const selectedMonth = this.selectedDate.getMonth();
            const selectedYear = this.selectedDate.getFullYear();
            const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
            this.weeksInSelectedMonth = [];

            const currentDate = new Date();
            for (let i = 1; i <= daysInMonth; i += 7) {
                const startDay = new Date(selectedYear, selectedMonth, i);
                const endDay = new Date(selectedYear, selectedMonth, Math.min(i + 6, daysInMonth));

                if (startDay <= currentDate) {
                    this.weeksInSelectedMonth.push({
                        text: `Tuần ${this.getWeekOfYear(startDay)}`,
                        value: {
                            start: this.vietnamToUTCFormatted(startDay),
                            end: this.vietnamToUTCFormatted(endDay)
                        }
                    });
                }
            }

            this.selectedWeek = this.weeksInSelectedMonth.length > 0 ? this.weeksInSelectedMonth[this.weeksInSelectedMonth.length - 1].value : null;
            if (this.selectedWeek) {
                this.updateDayCategories();
            }
        },
        async fetchData(range) {
            const from_date_utc = range.start;
            const to_date_utc = range.end;

            const response = await axios.get('https://api-misa.zigisoft.com/get_report_tien_gui_ngan_hang.php', {
                params: {
                    from_date: from_date_utc,
                    to_date: to_date_utc,
                    page_size: 100000,
                    page_index: 1,
                    p_account_number: this.selectedCurrency,
                }
            });

            const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
            const pageData = response.data.Data[key].PageData;
            let totalClosingAmount = [];

            for (let item of pageData) {
                if (item._data && item._data.length > 0) {
                    const bank_account_number = item._data[0].bank_account_number;
                    if (this.selectedCurrency === '1122') {
                        if (bank_account_number === '1026830229' || bank_account_number === '811881188') { // USD
                            totalClosingAmount.push({
                                "bank_account_number": bank_account_number,
                                "closing_amount": item._data[0].closing_amount
                            });
                        }
                    } else if (this.selectedCurrency === '1121') { // VND
                        totalClosingAmount.push({
                            "bank_account_number": bank_account_number,
                            "closing_amount": item._data[0].closing_amount
                        });
                    } else if (this.selectedCurrency === '112') {
                        if (bank_account_number === '104810220000220') { // RUB
                            totalClosingAmount.push({
                                "bank_account_number": bank_account_number,
                                "closing_amount": item._data[0].closing_amount
                            });
                        }
                    }
                }
            }
            const responseExport = await axios.get('https://api-misa.zigisoft.com/get_report_tien_gui_ngan_hang.php', {
                params: {
                    from_date: from_date_utc,
                    to_date: to_date_utc,
                    page_size: 100000,
                    page_index: 1,
                    p_account_number: '112',
                }
            });
            const keyExport = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
            const pageDataExport = responseExport.data.Data[keyExport].PageData;
            let exportData = []
            const foreignCurrencyPromises = [];

            for (let item of pageDataExport) {
                if (item._data && item._data.length > 0) {
                    const bank_account_number = item._data[0].bank_account_number;
                    const closing_amount = item._data[0].closing_amount;
                    exportData.push({
                        "date": `${this.formatDateUtcToVn(from_date_utc)} - ${this.formatDateUtcToVn(to_date_utc)}`,
                        "bank_account_number": bank_account_number,
                        "closing_amount": closing_amount,
                        "foreign_currency_amount": 0
                    });

                    const currencyType = bank_account_number === '104810220000220' ? 'RUB' : 'USD';
                    const selectedAccount = bank_account_number === '104810220000220' ? '112' : '1122';
                    const bankAccountId = this.getBankAccountId(bank_account_number);
                    const promise = this.fetchForeignCurrencyAmount(bankAccountId, currencyType, selectedAccount, from_date_utc, to_date_utc);
                    foreignCurrencyPromises.push(promise);
                }
            }

            const foreignCurrencyResults = await Promise.all(foreignCurrencyPromises);

            exportData.forEach((account, index) => {
                account.foreign_currency_amount = foreignCurrencyResults[index];
            });
            if (exportData.length > 0) {
                exportData.push({});
            }
            return {
                totalClosingAmount: totalClosingAmount,
                exportData: exportData
            };
        },
        getBankAccountId(bankAccountNumber) {
            const accountIds = {
                '1026830229': 'c40b7c3f-a312-417a-bc6f-8afda2b86567',
                '811881188': 'c565db77-ad59-4b5d-a022-c6758e3788f9',
                '104810220000220': 'becb1c01-caf7-46bd-8014-d075d1d9ee5d'
            };
            return accountIds[bankAccountNumber];
        },
        async fetchForeignCurrencyAmount(bankAccountId, currencyType, selectedAccount, from_date_utc, to_date_utc) {
            const params = {
                from_date: from_date_utc,
                to_date: to_date_utc,
                page_size: 100000,
                page_index: 1,
                loai_tien: currencyType,
                p_bank_account_id: bankAccountId,
                p_account_number: selectedAccount,
            };

            try {
                const response = await axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tien_gui_ngan_hang.php', { params });
                const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
                const data = response.data.Data[key].PageData;
                if (data && data.length > 0 && data[0]._data && data[0]._data.length > 0) {
                    return data[0]._data[0].closing_amount_oc || 0;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        async updateSeriesData(ranges) {
            const data = {};
            this.isLoading = true;
            let temporaryExportData = []

            for (const range of ranges) {
                const closingAmounts = await this.fetchData(range);
                temporaryExportData.push(...closingAmounts.exportData); // Store data for export

                for (const item of closingAmounts.totalClosingAmount) {
                    if (!data[item.bank_account_number]) {
                        data[item.bank_account_number] = [];
                    }
                    data[item.bank_account_number].push(item.closing_amount);
                }
            }
            this.exportData = temporaryExportData; // Clear previous export data
            this.series = Object.keys(data).map(key => ({
                name: key,
                data: data[key],
            }));
            this.isLoading = false;
        },
        handleWeekChange() {
            this.isLoading = true;
            this.updateDayCategories();
        },
    },
    mounted() {
        this.updateCategories();
    },
};
</script>

<style scoped>
.card_content {
    padding: 15px;
}

.card_button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.display-mode-select {
    margin-right: 10px;
    width: 250px;
}

.monthYearPicker,
.yearPicker,
.week-select {
    margin-right: 10px;
    width: 150px;
}

.btn-normal-text {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-normal-text:hover {
    background-color: #0056b3;
}

.week-select {
    width: 190px;
}

.currency-select {
    width: 250px;
}

.display_select {
    display: flex;
    gap: 15px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.select_two {
    font-size: 0;
}

.select_two>div {
    display: inline-block;
    vertical-align: middle;
    font-size: initial;
}

.select_two .currency-select {
    width: 250px;
    margin-right: 10px;
}
</style>
