<template>
  <div class="company-infomation-tab__edit h-100">
    <div class="each-info-part">
      <p class="info-part__title no-border-top">Company Infomation Edit</p>
      <v-row class="each-part pt-1">
        <v-col xl="6" lg="6" cols="12">
          <div class="row-info">
            <p class="row-info__label">
              Name<span class="required-star">*</span>
            </p>
            <input
              v-model="form.name"
              @input="convertToUpperCase('name')"
              type="text"
              class="row-info__content"
            />
          </div>
          <!-- EMAIL  -->
          <div class="row-info mt-2">
            <p class="row-info__label">
              Email<span class="required-star">*</span>
            </p>
            <div class="row-info__content">
              <div
                v-for="(email, idx) in listEmail"
                :key="idx"
                class="mb-3 d-flex align-items-center"
              >
                <input
                  v-model="email.txt"
                  type="text"
                  class="row-info__content w-100"
                />
                <v-tooltip v-if="listEmail.length > 1" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      v-bind="attrs"
                      v-on="on"
                      src="@/assets/imgs/icons/red-close-icon.svg"
                      alt="remove-icon"
                      class="pointer ml-1"
                      :class="idx === 0 ? 'remove-icon-disabled' : ''"
                      @click="removeEmail(idx)"
                    />
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
              <p class="add-email-btn pointer mb-3" @click="addEmail">
                <img
                  src="@/assets/imgs/icons/blue-add-circle-icon.svg"
                  alt=""
                  class="mr-2"
                />
                Add more Email
              </p>
            </div>
          </div>
          <!-- TYPE -->
          <div class="row-info mt-2">
            <p class="row-info__label">
              Type<span class="required-star">*</span>
            </p>

            <div class="row-info__content">
              <v-autocomplete
                class="
                  v-autocomplete__nodetails
                  v-autocomplete__noshadow
                  v-autocomplete__borderform
                  v-autocomplete__modappend
                  v-autocomplete__formheight
                "
                v-model="form.type"
                :items="companyTypes"
                :return-object="true"
                item-text="name"
                dense
                label=""
                solo
              >
                <template v-slot:append
                  ><div class="v-auto-append-icon">
                    <img
                      src="@/assets/imgs/icons/menu-down-icon.svg"
                      alt="back-icon"
                      height="22px"
                      class="pointer mx-1"
                    />
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </div>
          <!-- WEBSITE -->
          <div class="row-info">
            <p class="row-info__label">Website</p>
            <input
              v-model="form.website"
              type="text"
              class="row-info__content"
            />
          </div>
        </v-col>
        <!-- RS -->
        <v-col xl="6" lg="6" cols="12">
          <!--  -->
          <div class="row-info">
            <p class="row-info__label">
              Country<span class="required-star">*</span>
            </p>
            <!-- <p class="row-info__content">
            {{ currentDetail.country ? currentDetail.country.name : "" }}
          </p> -->
            <div class="row-info__content">
              <v-autocomplete
                class="
                  v-autocomplete__nodetails
                  v-autocomplete__noshadow
                  v-autocomplete__borderform
                  v-autocomplete__modappend
                  v-autocomplete__formheight
                "
                v-model="form.country"
                :items="countries"
                item-text="name"
                :return-object="true"
                dense
                label=""
                solo
              >
                <template v-slot:append
                  ><div class="v-auto-append-icon">
                    <img
                      src="@/assets/imgs/icons/menu-down-icon.svg"
                      alt="back-icon"
                      height="22px"
                      class="pointer mx-1"
                    />
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </div>
          <!-- CITY -->
          <div class="row-info">
            <p class="row-info__label">City</p>

            <div class="row-info__content">
              <v-autocomplete
                class="
                  v-autocomplete__nodetails
                  v-autocomplete__noshadow
                  v-autocomplete__borderform
                  v-autocomplete__modappend
                  v-autocomplete__formheight
                "
                :disabled="
                  !form.country || (form.country && form.country.id !== 233)
                "
                v-model="form.city"
                :items="cities"
                item-text="name"
                :return-object="true"
                dense
                label=""
                solo
              >
                <template v-slot:append
                  ><div class="v-auto-append-icon">
                    <img
                      src="@/assets/imgs/icons/menu-down-icon.svg"
                      alt="back-icon"
                      height="22px"
                      class="pointer mx-1"
                    />
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </div>
          <!-- INDUSTRY -->
          <div class="row-info">
            <p class="row-info__label">Industry</p>

            <div class="row-info__content">
              <v-autocomplete
                class="
                  v-autocomplete__nodetails
                  v-autocomplete__noshadow
                  v-autocomplete__borderform
                  v-autocomplete__modappend
                  v-autocomplete__formheight
                "
                v-model="form.industries"
                :items="industries"
                dense
                :multiple="true"
                item-text="name"
                :return-object="true"
                label=""
                solo
              >
                <template v-slot:append
                  ><div class="v-auto-append-icon">
                    <img
                      src="@/assets/imgs/icons/menu-down-icon.svg"
                      alt="back-icon"
                      height="22px"
                      class="pointer mx-1"
                    />
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </div>
          <!--  -->
          <div class="row-info">
            <p class="row-info__label">Stage</p>

            <div class="row-info__content">
              <v-autocomplete
                class="
                  v-autocomplete__nodetails
                  v-autocomplete__noshadow
                  v-autocomplete__borderform
                  v-autocomplete__modappend
                  v-autocomplete__formheight
                "
                v-model="form.stage"
                :items="companyStages"
                :return-object="true"
                item-text="name"
                dense
                label=""
                solo
              >
                <template v-slot:append
                  ><div class="v-auto-append-icon">
                    <img
                      src="@/assets/imgs/icons/menu-down-icon.svg"
                      alt="back-icon"
                      height="22px"
                      class="pointer mx-1"
                    />
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </div>
          <!-- AO -->
          <div class="row-info">
            <p class="row-info__label">
              Account Owner <span class="required-star">*</span>
            </p>
            <div class="row-info__content">
              <v-autocomplete
                class="
                  v-autocomplete__nodetails
                  v-autocomplete__noshadow
                  v-autocomplete__borderform
                  v-autocomplete__modappend
                  v-autocomplete__formheight
                "
                v-model="form.account_owner_id"
                :items="listUser"
                item-text="name"
                item-value="id"
                dense
                label=""
                solo
              >
                <template v-slot:append
                  ><div class="v-auto-append-icon">
                    <img
                      src="@/assets/imgs/icons/menu-down-icon.svg"
                      alt="back-icon"
                      height="22px"
                      class="pointer mx-1"
                    />
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- % -->
    <div class="each-info-part">
      <p class="info-part__title">Billing Information</p>
      <v-row class="each-part pt-1">
        <v-col class="row-info" xl="6" lg="6" cols="12">
          <p class="row-info__label">Company Name</p>
          <input
            v-model="billingInfomation.company_name"
            type="text"
            class="row-info__content"
          />
        </v-col>
        <v-col class="row-info" xl="6" lg="6" cols="12">
          <p class="row-info__label">
            Tax code<span class="required-star">*</span>
          </p>
          <input
            v-model="billingInfomation.tax_code"
            type="text"
            class="row-info__content"
          />
        </v-col>
        <v-col class="row-info" cols="12">
          <p class="row-info__label">Address</p>
          <input
            v-model="billingInfomation.address"
            type="text"
            class="row-info__content"
          />
        </v-col>
      </v-row>
    </div>
    <!-- % -->
    <div class="each-info-part">
      <p class="info-part__title info-part__title-icon">
        Additional Information
        <!-- <img
          src="@/assets/imgs/icons/bxs-minus-circle-icon.svg"
          class="ml-2"
          alt="bxs-minus-circle-icon"
        /> -->
      </p>
      <v-row class="each-part pt-1">
        <v-col class="row-info" xl="6" lg="6" cols="12">
          <p class="row-info__label">Social network:</p>
          <div class="row-info__content">
            <div
              class="
                w-100
                row-info__content
                border-form-block
                d-flex
                align-items-center
                p-1
                mb-2
              "
            >
              <img
                src="@/assets/imgs/icons/facebook-icon.svg"
                class="social__icon"
                alt="facebook-icon"
              />
              <input
                v-model="socialNetwork.facebook"
                type="text"
                class="w-100"
              />
            </div>
            <div
              class="
                w-100
                row-info__content
                border-form-block
                d-flex
                align-items-center
                p-1
              "
            >
              <img
                src="@/assets/imgs/icons/linkedin-icon.svg"
                class="social__icon"
                alt="linkedin-icon"
              />
              <input
                v-model="socialNetwork.linked_in"
                type="text"
                class="w-100"
              />
            </div>
          </div>
        </v-col>
        <v-col xl="6" lg="6" cols="12" class="py-1">
          <div class="row-info mb-3">
            <p class="row-info__label">Tel:</p>
            <div class="row-info__content">
              <vue-tel-input
                v-if="loadedData"
                v-model="form.phone"
                :dropdownOptions="phoneDropdownOptions"
                :mode="'international'"
                v-on:country-changed="countryChanged"
              ></vue-tel-input>
            </div>
          </div>
          <div class="row-info p-tb-12">
            <p class="row-info__label">Anniversary:</p>
            <div class="row-info__content date-open-top">
              <div
                v-for="(ani, idx) in anniversaries"
                :key="idx"
                class="mb-3 d-flex align-items-center"
              >
                <input
                  v-model="ani.title"
                  type="text"
                  class="row-info__content w-100"
                />
                <div class="single-date-wr">
                  <DateRangeTableHeader
                    :singleDatePicker="true"
                    :show-ranges="false"
                    :picked-date="pickedDateAni"
                    :iconPrepend="false"
                    :placeholder-picker="'DD/MM/YYYY'"
                    :table-field="`${idx}`"
                    :format-default-date="'dmy'"
                    :opens="'left'"
                    :default-date="
                      ani && ani.start_at
                        ? {
                            startDate: String(ani.start_at).replaceAll(
                              '/',
                              '-',
                            ),
                            endDate: String(ani.start_at).replaceAll('/', '-'),
                          }
                        : {}
                    "
                    :picker-class="'date-picker__form'"
                    :wrapper-class="'date-picker-single-mod'"
                  />
                </div>

                <v-tooltip v-if="anniversaries.length > 1" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      v-bind="attrs"
                      v-on="on"
                      src="@/assets/imgs/icons/red-close-icon.svg"
                      alt="remove-icon"
                      class="pointer ml-1"
                      :class="idx === 0 ? 'remove-icon-been-hide' : ''"
                      @click="removeAni(idx)"
                    />
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
              <p class="add-email-btn pointer mb-3" @click="addAni">
                <img
                  src="@/assets/imgs/icons/blue-add-circle-icon.svg"
                  alt=""
                  class="mr-2"
                />
                Add new anniversary
              </p>
            </div>
          </div>
        </v-col>
        <v-col class="row-info" cols="12">
          <p class="row-info__label">Other location:</p>

          <div class="row-info__content">
            <div
              v-for="(location, idx) in otherLocations"
              :key="idx"
              class="mb-3 d-flex align-items-center"
            >
              <div class="row-info__content w-100 d-flex align-items-start">
                <v-autocomplete
                  class="
                    v-autocomplete__nodetails
                    v-autocomplete__noshadow
                    v-autocomplete__borderform
                    v-autocomplete__modappend
                    v-autocomplete__formheight
                  "
                  v-model="location.type"
                  :items="locationTypes"
                  item-value="id"
                  :return-object="false"
                  item-text="name"
                  dense
                  label=""
                  solo
                >
                  <template v-slot:append
                    ><div class="v-auto-append-icon">
                      <img
                        src="@/assets/imgs/icons/menu-down-icon.svg"
                        alt="back-icon"
                        height="22px"
                        class="pointer mx-1"
                      />
                    </div>
                  </template>
                </v-autocomplete>
                <input
                  v-model="location.address"
                  type="text"
                  class="row-info__content w-100 ml-3"
                />
              </div>
              <v-tooltip v-if="otherLocations.length > 1" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    v-bind="attrs"
                    v-on="on"
                    src="@/assets/imgs/icons/red-close-icon.svg"
                    alt="remove-icon"
                    class="pointer ml-1"
                    @click="removeLocation(idx)"
                  />
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
            <p class="add-email-btn pointer mb-3" @click="addLocation">
              <img
                src="@/assets/imgs/icons/blue-add-circle-icon.svg"
                alt=""
                class="mr-2"
              />
              Add more Location
            </p>
          </div>
        </v-col>
        <v-col class="row-info" cols="12">
          <p class="row-info__label">Note</p>

          <div class="row-info__content">
            <textarea
              v-model="form.note"
              rows="3"
              class="row-info__content w-100 mr-1"
              placeholder=""
            >
            </textarea>
          </div>
        </v-col>
        <v-col class="row-info" cols="12">
          <p class="row-info__label">File attachments</p>
          <div class="row-info__content d-flex align-center flex-wrap">
            <label
              for="companyAttachment"
              class="pointer label-browse-file"
              @click="handleRemoveCacheFile('companyAttachment')"
              >Browse</label
            >
            <input
              type="file"
              id="companyAttachment"
              class="d-none"
              multiple
              @change="handleChangeFile"
            />
            <div
              v-for="file in attachmentsComputed"
              :key="file.id"
              class="each-file-in-attachments"
            >
              <a
                :href="file.url"
                :n="file.url"
                target="_blank"
                class="row-info__content file-name text-no-wrap"
                >{{ file.original_name }}.{{ file.extension }}</a
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    v-bind="attrs"
                    v-on="on"
                    src="@/assets/imgs/icons/red-close-icon.svg"
                    alt="remove-icon"
                    class="pointer ml-1"
                    height="9px"
                    @click="removeFile(file)"
                  />
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="action-gr-btn d-flex align-items-center update-gr-btn">
      <v-btn
        depressed
        color="#A7A7A700"
        class="
          btn-normal-text btn-actions-tabs
          bc-btn
          btn-font-weight-regular btn-font-color-66
          mr-3
          ml-auto
        "
        :disabled="loadingUpdate"
        @click="backGeneralInfo"
      >
        Cancel
      </v-btn>
      <v-btn
        depressed
        :color="returnThemeColorBtnSC()"
        class="
          btn-normal-text btn-actions-tabs
          bc-btn
          btn-font-weight-regular btn-font-color-66
          text-white
          v-btn__textwhite
        "
        :disabled="loadingUpdate"
        :loading="loadingUpdate"
        @click="updateDetail"
      >
        Update
      </v-btn>
    </div>
  </div>
</template>

<script>
import objHandlerMixins from "@/mixins/objHandlerMixins.js"
import api from "@/services"
import dateMixins from "@/mixins/dateMixins.js"
import DateRangeTableHeader from "@/components/Shared/DateRangeTableHeader.vue"
import { mapState } from "vuex"
export default {
  name: "CompanyGeneralInfomationEdit",
  props: {
    currentDetail: {
      type: Object,
    },
    currentId: {
      type: null,
    },
    updateCurrentData: {
      type: Function,
    },
    backGeneralInfo: {
      type: Function,
    },
  },
  components: { DateRangeTableHeader },
  data() {
    return {
      form: {},
      billingInfomation: {},
      socialNetwork: {},
      otherLocations: [],
      anniversaries: [],
      listEmail: [],
      values: "",
      defaultCountry: "",
      phoneCountry: null,
      phoneNumber: "",
      loadedData: false,
      loadingUpdate: false,
      phoneDropdownOptions: {
        showDialCodeInSelection: true,
        showSearchBox: true,
        showFlags: true,
        showDialCodeInList: true,
      },
      listRemovedFile: [],
    }
  },
  mixins: [objHandlerMixins, dateMixins],
  computed: {
    ...mapState({
      countries: (state) => state.others.countries,
      cities: (state) => state.others.cities,
      industries: (state) => state.others.industries,
      companyTypes: (state) => state.others.companyTypes,
      companyStages: (state) => state.others.companyStages,
      locationTypes: (state) => state.others.locationTypes,
      listUser: (state) => state.others.listUser,
    }),
    attachmentsComputed() {
      const arr = this.currentDetail.attachments
        ? [...this.currentDetail.attachments]
        : []
      return arr.filter((o) => !this.listRemovedFile.includes(o.id)) || []
    },
  },
  watch: {
    currentDetail: {
      deep: true,
      handler() {
        this.parseData()
      },
    },
  },
  mounted() {
    this.parseData()
  },
  methods: {
    convertToUpperCase(fieldName) {
      this.form[fieldName] = this.form[fieldName].toUpperCase()
    },
    parseData() {
      this.form = this.currentDetail ? this.currentDetail : {}
      this.phoneNumber = this.currentDetail.phone
      this.listEmail = this.currentDetail.emails
        ? this.currentDetail.emails.map((o) => ({ txt: o }))
        : []
      this.anniversaries = this.currentDetail.anniversaries
        ? this.currentDetail.anniversaries.slice()
        : []
      this.otherLocations =
        this.currentDetail && this.currentDetail.other_locations
          ? this.currentDetail.other_locations
          : []
      this.billingInfomation =
        this.currentDetail && this.currentDetail.billing_information
          ? this.currentDetail.billing_information
          : {}

      this.socialNetwork =
        this.currentDetail && this.currentDetail.social_network
          ? this.currentDetail.social_network
          : {}
      this.$nextTick(() => {
        this.loadedData = true
      })
    },
    addEmail() {
      this.listEmail.push({ txt: "" })
    },
    addLocation() {
      this.otherLocations.push({ type: "", address: "" })
    },
    addAni() {
      this.anniversaries.push({ start_at: null, title: "" })
    },
    removeEmail(idx) {
      this.listEmail.splice(idx, 1)
    },
    removeAni(idx) {
      this.anniversaries.splice(idx, 1)
    },
    removeLocation(idx) {
      this.otherLocations.splice(idx, 1)
    },
    handleParseAniDate(value) {
      if (!value) {
        return null
      }
      const pValAr = value.split("-")

      return `${pValAr[2]}-${pValAr[1]}-${pValAr[0]} 00:00:00`
    },
    pickedDateAni(_val) {
      const idx = +_val.fieldUpdate
      const obj = { ...this.anniversaries[idx] }
      obj.start_at = this.handleParseAniDate(_val.value.startDate)
      this.anniversaries.splice(idx, 1, obj)
    },
    countryChanged(country) {
      this.phoneCountry = country
    },
    async updateDetail() {
      this.loadingUpdate = true
      // const formData = new FormData()
      // REQUIRED FIELD
      const billing_information = {
        ...this.billingInfomation,
      }
      const anniversaries = this.anniversaries.map((o) => ({
        title: o.title,
        start_at: o.start_at,
      }))
      const social_network = { ...this.socialNetwork }
      const other_locations = [...this.otherLocations]

      const body = {
        name: this.form.name,
        emails: this.listEmail.filter((o) => o.txt).map((j) => j.txt),
        phone: String(this.form.phone).replaceAll(" ", ""),
        website: this.form.website,
        note: this.form.note,
        country_id: this.form.country ? this.form.country.id : null,
        city_id: this.form.city ? this.form.city.id : null,
        type_id: this.form.type ? this.form.type.id : null,
        stage_id: this.form.stage ? this.form.stage.id : null,
        industry_ids: this.form.industries.map((o) => o.id),
        account_owner_id: this.form.account_owner_id,
        billing_information,
        anniversaries,
        social_network,
        other_locations,
        // other_locations
      }
      if (body.country_id && body.country_id !== 233) {
        delete body.city_id
      }
      if (!body.city_id) {
        delete body.city_id
      }
      let res
      if (
        this.billingInfomation.tax_code == "" ||
        this.billingInfomation.tax_code == undefined ||
        this.billingInfomation.tax_code == null
      ) {
        this.$store.commit(
          "toast/getError",
          "Failed when edit company: The tax code field is required",
        )
      } else {
        res = await api.company.update(this.currentId, body)
      }
      this.loadingUpdate = false
      if (!res) {
        return null
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.$store.commit("toast/getSuccess", "Successfully Updated")
          this.updateCurrentData(res.data.data.company)
          this.backGeneralInfo()
          return
        }
        const msg =
          "Failed when get industries: " +
          (res.data.message || String(res) || " Try again")
        this.$store.commit("toast/getError", msg)
      } catch (error) {
        const msg =
          "Failed when get industries: " + error.message ||
          error.data.message ||
          String(error) ||
          " Try again"
        this.$store.commit("toast/getError", msg)
      }
    },
    async removeFile(file) {
      const body = {
        attachment_id: file.id,
      }

      const res = await api.company.removeAttachment(this.currentId, body)
      this.loadingUpdate = false
      if (!res) {
        return null
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.$store.commit("toast/getSuccess", "Successfully Updated")
          // this.updateCurrentData(res.data.data.company)
          this.listRemovedFile.push(file.id)
          return
        }
        const msg =
          "Failed when remove file: " +
          (res.data.message || String(res) || " Try again")
        this.$store.commit("toast/getError", msg)
      } catch (error) {
        const msg =
          "Failed when remove file: " + error.message ||
          error.data.message ||
          String(error) ||
          " Try again"
        this.$store.commit("toast/getError", msg)
      }
    },
    handleRemoveCacheFile(cacheEleId) {
      try {
        document.querySelector(`#${cacheEleId}`).value = null
      } catch (error) {
        console.log(error)
      }
    },
    async handleChangeFile(event) {
      const files = event.target.files
      const attachments = []
      for (let file of files) {
        attachments.push({
          _id: `${new Date().getTime()}_${Math.random()}`,
          name: file.name,
          type: "file",
          blob: file,
        })
      }
      const formData = new FormData()
      attachments.forEach((f) => {
        formData.append("attachments[]", f.blob)
      })
      this.loadingUpdate = true
      const res = await api.company.addAttachment(this.currentId, formData)
      this.loadingUpdate = false
      if (!res) {
        return null
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.$store.commit("toast/getSuccess", "Successfully Updated")
          this.updateCurrentData(res.data.data.company)
          return
        }
        const msg =
          "Failed when add file: " +
          (res.data.message || String(res) || " Try again")
        this.$store.commit("toast/getError", msg)
      } catch (error) {
        const msg =
          "Failed when add file: " + error.message ||
          error.data.message ||
          String(error) ||
          " Try again"
        this.$store.commit("toast/getError", msg)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.company-infomation-tab__edit {
  border: 1px solid #cfd4d8;
  padding-bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15);
  }
  .required-star {
    padding-left: 4px;
  }
  .each-part {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 18px;
  }
  .info-part__title {
    border-top: 1px solid #cfd4d8;
    border-bottom: 1px solid #cfd4d8;
    padding: 16px 30px;
    background-color: #f8f8fa;
    color: #000;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    &.no-border-top {
      border-top: unset;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }
  .row-info {
    display: flex;
    align-items: flex-start;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    &.p-tb-12 {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    &__label {
      margin-bottom: 0;
      /* padding-top: 4px; */
      width: 150px;
      white-space: nowrap;
      color: #000;
      font-weight: bold;
      line-height: 19px;
    }
    &__content {
      margin-bottom: 0;
      width: calc(100% - 155px);
      color: #000;
      line-height: 19px;
    }
  }
  input.row-info__content {
    border: 1px solid #cfd4d8;
    border-radius: 4px;
    padding: 6px 12px;
    height: 32px;
    /* width: 100%; */
  }
  .border-form-block {
    border: 1px solid #cfd4d8;
    border-radius: 4px;
  }
  .social {
    &__content {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &__icon {
      width: 26px;
      height: 26px;
      object-fit: cover;
      margin-right: 12px;
    }
  }
  .other-location {
    &__type {
      text-transform: capitalize;
    }
  }
}
.add-email-btn {
  color: #0a71bc;
  font-weight: 500;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.single-date-wr {
  width: 200px !important;
  height: 32px !important;
  margin-left: 12px;
  .vue-daterange-picker {
    width: 100% !important;
    height: 100% !important;
  }
}
.update-gr-btn {
  position: fixed;
  bottom: 16px;
  right: 30px;
}
.each-file-in-attachments {
  padding: 6px 12px;
  margin-right: 6px;
  background-color: #f0f0f0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  .file-name {
    display: block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0a71bc;
    margin-bottom: 0px;
  }
}
.label-browse-file {
  background: #f8f8fa 0% 0% no-repeat padding-box;
  border: 1px solid #cfd4d8;
  border-radius: 4px;
  font-weight: bold;
  line-height: 40px;
  width: 150px;
  display: block;
  text-align: center;
  height: 40px;
  margin-right: 12px;
  margin-bottom: 8px;
}
.remove-icon-been-hide {
  opacity: 0;
  pointer-events: none;
}
</style>
