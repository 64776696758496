import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=05442e4d&scoped=true"
import script from "./Events.vue?vue&type=script&lang=js"
export * from "./Events.vue?vue&type=script&lang=js"
import style0 from "./Events.vue?vue&type=style&index=0&id=05442e4d&prod&lang=scss&scoped=true"
import style1 from "./Events.vue?vue&type=style&index=1&id=05442e4d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05442e4d",
  null
  
)

export default component.exports