<template>
    <v-dialog v-model="dialog" max-width="90%">
        <v-card>
            <v-card-title>
                <h3>Report Details Profit
                    <template v-if="fullSelectedWeek?.display !== 'Tổng hợp'">
                        {{ formatDateUtcToVn(fullSelectedWeek?.start) }} - {{ formatDateUtcToVn(fullSelectedWeek?.end)
                        }}
                    </template>
                    <template v-else>
                        (Synthetic)
                    </template>
                </h3>
                <v-spacer></v-spacer>
                <div class="select-list">
                    <v-autocomplete
                        class="display-mode-select v-autocomplete__nodetails v-autocomplete__noshadow v-autocomplete__borderform v-autocomplete__modappend v-autocomplete__formheight v-autocomplete__template-status"
                        v-model="selectedWeek" :items="aggregatedData" item-value="display" :placeholder="'Choose Data'"
                        :color="`#e04038`" dense label="" solo>
                        <template v-slot:append>
                            <div class="v-auto-append-icon">
                                <img src="@/assets/imgs/icons/menu-down-icon.svg" alt="back-icon" height="22px"
                                    class="pointer mx-1" />
                            </div>
                        </template>
                        <template v-slot:item="{ item, on, attrs }">
                            <p class="mb-0 font-weight-regular" v-bind="attrs" v-on="on">
                                {{ item.display }}
                            </p>
                        </template>
                        <template v-slot:selection="{ item, on, attrs }">
                            <p class="mb-0" v-bind="attrs" v-on="on">
                                {{ item.display }}
                            </p>
                        </template>
                    </v-autocomplete>

                </div>

            </v-card-title>
            <v-card-text>
                <div style="position: relative; width: 100%;">
                    <div class="filter-block py-3 px-3">
                        <div class="btn_reload" v-tooltip="'Reload data table'">
                            <img src="@/assets/imgs/icons/sync-icon.svg" alt="company-icon" class="reload__icon__reload"
                                v-on:click="reloadData" />
                        </div>
                        <div class="btn_excel" v-tooltip="'Export to Excel'">
                            <export-excel :data="items_export" :name="generateExcelFileName()">
                                <img src="@/assets/imgs/icons/excel-icon.svg" alt="company-icon" class="excel__icon" />
                            </export-excel>
                        </div>
                    </div>
                    <v-overlay :value="loading">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                    <ag-grid-vue id="myGridSidebar" style="width: 100%; height: 650px;" class="ag-theme-alpine"
                        :columnDefs="columnDefs" :rowData="rowData" :domLayout="'autoHeight'"
                        :defaultColDef="defaultColDef" :pagination="true" :paginationPageSize="12" :pivotMode="false"
                        :rowSelection="'multiple'" :pinnedBottomRowData="pinnedBottomRowData" :getRowClass="getRowClass"
                        @grid-ready="onGridReady">
                    </ag-grid-vue>

                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import axios from 'axios';
import { VAutocomplete } from 'vuetify/lib';

export default {
    components: {
        AgGridVue,
        VAutocomplete,
    },
    props: ['profit', 'range', 'dates'],
    data() {
        return {
            dialog: false,
            loading: false,
            selectedAccountKey: '5113',
            // paginationPageSizeSelector: [12, 24, 36, 50, 100],
            // paginationPageSize: 12,
            accountKeys: [
                { text: 'Revenue', value: '5113', electric: true },
                { text: 'Cost', value: '154' },
            ],
            selectedWeek: this.dates && this.dates.length > 0 ? this.dates[0].display : null,
            fullSelectedWeek: null,
            rowData: [],

            defaultColDef: {
                flex: 1,
                minWidth: 150,
                filter: false,
                suppressMenuHide: true,
                menuTabs: ["filterMenuTab"],
            },
            pinnedBottomRowData: [{
                job_code: 'Tổng',
                revenue: 0,
                cost: 0,
                cp_com: 0,
                cp_fct: 0,
                profit: 0
            }],
            dataExport: [],
        };
    },
    computed: {
        columnDefs() {
            if (this.fullSelectedWeek?.display === 'Tổng hợp') {
                return [
                    {
                        headerName: "STT",
                        valueGetter: "node.rowIndex + 1",
                        maxWidth: 80,
                    },
                    { headerName: "Name", field: "job_code", minWidth: 100 },
                    {
                        headerName: "Revenue",
                        field: "revenue",
                        minWidth: 100,
                        cellRenderer: params => this.formatNumber(params.value)
                    },
                    {
                        headerName: "Cost",
                        field: "cost",
                        minWidth: 100,
                        cellRenderer: params => this.formatNumber(params.value)
                    },
                    {
                        headerName: "CP.COM",
                        field: "cp_com",
                        minWidth: 100,
                        cellRenderer: params => this.formatNumber(params.value)
                    },
                    {
                        headerName: "CP.FCT",
                        field: "cp_fct",
                        minWidth: 100,
                        cellRenderer: params => this.formatNumber(params.value)
                    },
                    {
                        headerName: "Profit",
                        field: "profit",
                        minWidth: 100,
                        cellRenderer: params => this.formatNumber(params.value)
                    }
                ]
            } else {
                return [
                    {
                        headerName: "STT",
                        valueGetter: "node.rowIndex + 1",
                        maxWidth: 80,
                    },
                    { headerName: "Job Code", field: "job_code", minWidth: 100 },
                    {
                        headerName: "Revenue",
                        field: "revenue",
                        minWidth: 100,
                        cellRenderer: params => this.formatNumber(params.value)
                    },
                    {
                        headerName: "Cost",
                        field: "cost",
                        minWidth: 100,
                        cellRenderer: params => this.formatNumber(params.value)
                    },
                    {
                        headerName: "CP.COM",
                        field: "cp_com",
                        minWidth: 100,
                        cellRenderer: params => this.formatNumber(params.value)
                    },
                    {
                        headerName: "CP.FCT",
                        field: "cp_fct",
                        minWidth: 100,
                        cellRenderer: params => this.formatNumber(params.value)
                    },
                    {
                        headerName: "Profit",
                        field: "profit",
                        minWidth: 100,
                        cellRenderer: params => this.formatNumber(params.value)
                    }
                ]
            }
        },
        items_export() {
            return this.dataExport.map(data => {
                return {
                    "Job Code": data.job_code,
                    "Revenue": data.revenue,
                    "Cost": data.cost,
                    "CP COM": data.cp_com,
                    "CP FCT": data.cp_fct,
                    "Profit": data.profit
                }
            });
        },
        aggregatedData() {
            const combinedDates = {
                display: 'Tổng hợp',
                quarters: this.dates.map(quarter => ({
                    display: quarter.display,
                    start: quarter.start,
                    end: quarter.end
                }))
            };

            return [combinedDates].concat(this.dates.map(quarter => {
                return {
                    display: quarter.display,
                    start: quarter.start,
                    end: quarter.end
                };
            }));
        }
    },
    watch: {
        dialog(newVal) {
            if (newVal && this.aggregatedData && this.aggregatedData.length > 0) {
                this.selectedWeek = this.aggregatedData[0].display;
                this.fullSelectedWeek = this.aggregatedData[0];
                this.fetchData();
            }
        },
        selectedWeek(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.fullSelectedWeek = this.aggregatedData.find(date => date.display === newVal);
                if (this.fullSelectedWeek) {
                    this.fetchData();
                }
            }
        },
        paginationPageSize(newVal) {
            if (this.gridApi) {
                this.gridApi.paginationSetPageSize(newVal);
            }
        }
    },
    methods: {
        generateExcelFileName() {
            if (this.fullSelectedWeek?.display === 'Tổng hợp') {
                return 'Report Detail Synthetic';
            } else {
                return `Report Detail ${this.formatDateUtcToVn(this.fullSelectedWeek?.start)} - ${this.formatDateUtcToVn(this.fullSelectedWeek?.end)}`;
            }
        },
        formatDateUtcToVn(dateUtc) {
            const date = new Date(dateUtc);

            const vnTimeOffset = 7 * 60;
            const localTime = new Date(date.getTime() + vnTimeOffset * 60 * 1000);

            const day = localTime.getUTCDate();
            const month = localTime.getUTCMonth() + 1;
            const year = localTime.getUTCFullYear();

            const dayString = day < 10 ? '0' + day : day;
            const monthString = month < 10 ? '0' + month : month;

            return `${dayString}/${monthString}/${year}`;
        },
        formatNumber(value) {
            if (!value && value !== 0) return '';
            return value.toLocaleString('vi-VN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },
        fetchData() {
            if (!this.fullSelectedWeek) {
                return;
            }

            if (this.fullSelectedWeek.display === 'Tổng hợp') {
                this.fetchCombinedData(this.fullSelectedWeek.quarters);
            } else {
                this.loading = true;
                const params = {
                    from_date: this.fullSelectedWeek.start,
                    to_date: this.fullSelectedWeek.end,
                    account_number: '5113,632',
                    page_size: 1000000,
                    page_index: 1
                };
                axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tai_khoan.php', { params })
                    .then(response => {
                        const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
                        const data = response.data.Data[key].PageData;
                        this.processData(data);
                    })
                    .catch(error => console.error("Error fetching data:", error))
                    .finally(() => {
                        this.loading = false;
                    });
                this.calculateTotals();
                if (this.gridApi) {
                    this.gridApi.setRowData(this.rowData);
                }
            }
        },
        async fetchCombinedData(quarters) {
            this.loading = true;
            const combinedData = [];

            for (const quarter of quarters) {
                const params = {
                    from_date: quarter.start,
                    to_date: quarter.end,
                    account_number: '5113,632',
                    page_size: 1000000,
                    page_index: 1
                };

                try {
                    const response = await axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tai_khoan.php', { params });
                    const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
                    const data = response.data.Data[key].PageData;

                    const quarterData = {};

                    data.forEach(account => {
                        const accountKey = account._key;
                        if (!account._data) {
                            console.error('PageData is missing for account:', accountKey);
                            return;
                        }

                        account._data.forEach(item => {
                            const jobCode = item.job_code;
                            if (!jobCode) return;
                            if (!quarterData[jobCode]) {
                                quarterData[jobCode] = { revenue: 0, cost: 0, cp_com: 0, cp_fct: 0 };
                            }

                            if (accountKey === '5113') {
                                quarterData[jobCode].revenue += parseFloat(item.credit_amount || 0);
                            } else if (accountKey === '632') {
                                if (item.expense_item_code) {
                                    if (item.expense_item_code === 'CP.COM') {
                                        quarterData[jobCode].cp_com += parseFloat(item.debit_amount || 0);
                                    } else if (item.expense_item_code.includes('CP.FCT')) {
                                        quarterData[jobCode].cp_fct += parseFloat(item.debit_amount || 0);
                                    } else {
                                        quarterData[jobCode].cost += parseFloat(item.debit_amount || 0);
                                    }
                                } else {
                                    quarterData[jobCode].cost += parseFloat(item.debit_amount || 0);
                                }
                            }
                        });
                    });

                    const total = Object.values(quarterData).reduce((totals, job) => {
                        totals.revenue += job.revenue;
                        totals.cost += job.cost;
                        totals.cp_com += job.cp_com;
                        totals.cp_fct += job.cp_fct;
                        return totals;
                    }, { revenue: 0, cost: 0, cp_com: 0, cp_fct: 0 });

                    combinedData.push({
                        job_code: quarter.display,
                        revenue: total.revenue,
                        cost: total.cost,
                        cp_com: total.cp_com,
                        cp_fct: total.cp_fct,
                        profit: total.revenue - (total.cost + total.cp_com + total.cp_fct),
                    });

                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }

            this.rowData = combinedData;
            this.dataExport = combinedData;
            this.loading = false;

            this.calculateTotals();
            if (this.gridApi) {
                this.gridApi.setRowData(this.rowData);
            }
        },
        processData(data) {
            const jobData = {};
            data.forEach(account => {
                const accountKey = account._key;
                if (!account._data) {
                    console.error('PageData is missing for account:', accountKey);
                    return;
                }

                account._data.forEach(item => {

                    const jobCode = item.job_code;
                    if (!jobCode) return;
                    if (!jobData[jobCode]) {
                        jobData[jobCode] = { revenue: 0, cost: 0, cp_com: 0, cp_fct: 0, profit: 0 };
                    }

                    if (accountKey === '5113') {
                        jobData[jobCode].revenue += parseFloat(item.credit_amount || 0);
                    } else if (accountKey === '632') {

                        if (item.expense_item_code) {
                            if (item.expense_item_code === 'CP.COM') {
                                jobData[jobCode].cp_com += parseFloat(item.debit_amount || 0);
                            } else if (item.expense_item_code.includes('CP.FCT')) {
                                jobData[jobCode].cp_fct += parseFloat(item.debit_amount || 0);
                            } else {
                                jobData[jobCode].cost += parseFloat(item.debit_amount || 0);
                            }
                        } else {
                            jobData[jobCode].cost += parseFloat(item.debit_amount || 0);
                        }
                    }
                });
            });

            this.rowData = Object.entries(jobData).reduce((filtered, [jobCode, data]) => {
                if (data.revenue !== 0 || data.cost !== 0 || data.cp_com !== 0 || data.cp_fct !== 0) {
                    filtered.push({
                        job_code: jobCode,
                        revenue: data.revenue,
                        cost: data.cost,
                        cp_com: data.cp_com,
                        cp_fct: data.cp_fct,
                        profit: data.revenue - (data.cost + data.cp_com + data.cp_fct)
                    });
                }
                return filtered;
            }, []);
            this.dataExport = this.rowData;
        },
        calculateTotals() {
            let totalProfit = 0;
            let totalRevenue = 0;
            let totalCost = 0;
            let totalCpCom = 0;
            let totalCpFct = 0;
            this.rowData.forEach(row => {
                totalProfit += row.profit;
                totalRevenue += row.revenue;
                totalCost += row.cost;
                totalCpCom += row.cp_com;
                totalCpFct += row.cp_fct;
            });
            this.pinnedBottomRowData = [{
                job_code: 'Tổng',
                revenue: totalRevenue,
                cost: totalCost,
                cp_com: totalCpCom,
                cp_fct: totalCpFct,
                profit: totalProfit
            }];
        },
        getRowClass(params) {
            if (params.node.rowPinned) {
                return 'bold-text';
            }
            return '';
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            this.fetchData();
            this.calculateTotals();
            this.gridApi.addEventListener('paginationChanged', this.calculateTotals);
        },
        reloadData() {
            this.fetchData();
            this.calculateTotals();
        },
        openDialog() {
            this.dialog = true;

            this.fetchData();
        },
    }
}
</script>

<style>
.btn_reload {
    margin-right: 10px;
    margin-top: 4px;
    cursor: pointer;
}

.reload__icon__reload {
    filter: invert(0%) sepia(58%) saturate(2088%) hue-rotate(45deg) brightness(88%) contrast(99%);
    width: 33px;
    height: 34px;
    cursor: pointer;
}

.display-mode-select {
    margin-right: 10px;
    width: 200px;
}

.bold-text {
    font-weight: bold;
}

.pagination-controls {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.pagination-size-selector {
    width: 150px;
}
</style>
