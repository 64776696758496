const roleRepo = (axios) => {
  return {
    getListData(payload) {
      console.log(payload)
      return axios
        .get(`api/provider`, {
          params: payload,
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getDetailData(id, payload) {
      return axios
        .get(`api/provider/${id}`, {
          params: payload,
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    create(payload) {
      return axios
        .post(`api/provider`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    update(id, payload) {
      return axios
        .put(`api/provider/${id}`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    delete(id) {
      return axios
        .delete(`api/provider/${id}`)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    GetSyncData() {
      return axios
        .get(`api/command/sync-misa-provider`)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    }
  }
}

export default roleRepo
