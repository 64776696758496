<template>
    <v-dialog v-model="dialog" max-width="90%">
        <v-card>
            <v-card-title>
                <h3>Report Details
                    <template v-if="fullSelectedWeek?.display !== 'Tổng hợp'">
                        {{ formatDateUtcToVn(fullSelectedWeek?.start) }} - {{ formatDateUtcToVn(fullSelectedWeek?.end)
                        }}
                    </template>
                    <template v-else>
                        (Synthetic)
                    </template>
                </h3>
                <v-spacer></v-spacer>
                <div class="select-list">
                    <v-autocomplete
                        class="display-mode-select v-autocomplete__nodetails v-autocomplete__noshadow v-autocomplete__borderform v-autocomplete__modappend v-autocomplete__formheight v-autocomplete__template-status"
                        v-model="selectedWeek" :items="aggregatedData" item-value="display" :placeholder="'Choose Data'"
                        :color="`#e04038`" dense label="" solo>
                        <template v-slot:append>
                            <div class="v-auto-append-icon">
                                <img src="@/assets/imgs/icons/menu-down-icon.svg" alt="back-icon" height="22px"
                                    class="pointer mx-1" />
                            </div>
                        </template>
                        <template v-slot:item="{ item, on, attrs }">
                            <p class="mb-0 font-weight-regular" v-bind="attrs" v-on="on">
                                {{ item.display }}
                            </p>
                        </template>
                        <template v-slot:selection="{ item, on, attrs }">
                            <p class="mb-0" v-bind="attrs" v-on="on">
                                {{ item.display }}
                            </p>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete v-if="selectedWeek !== 'Tổng hợp'"
                        class="display-mode-select-two v-autocomplete__nodetails v-autocomplete__noshadow v-autocomplete__borderform v-autocomplete__modappend v-autocomplete__formheight v-autocomplete__template-status"
                        v-model="selectedReportType" :items="reportTypes" item-value="display"
                        :placeholder="'Choose Data'" :color="`#e04038`" dense label="" solo>
                        <template v-slot:append>
                            <div class="v-auto-append-icon">
                                <img src="@/assets/imgs/icons/menu-down-icon.svg" alt="back-icon" height="22px"
                                    class="pointer mx-1" />
                            </div>
                        </template>
                        <template v-slot:item="{ item, on, attrs }">
                            <p class="mb-0 font-weight-regular" v-bind="attrs" v-on="on">
                                {{ item }}
                            </p>
                        </template>
                        <template v-slot:selection="{ item, on, attrs }">
                            <p class="mb-0" v-bind="attrs" v-on="on">
                                {{ item }}
                            </p>
                        </template>
                    </v-autocomplete>

                </div>

            </v-card-title>
            <v-card-text>
                <div style="position: relative; width: 100%;">
                    <div class="filter-block py-3 px-3">
                        <div class="btn_reload" v-tooltip="'Reload data table'">
                            <img src="@/assets/imgs/icons/sync-icon.svg" alt="company-icon" class="reload__icon__reload"
                                v-on:click="reloadData" />
                        </div>
                        <div class="btn_excel" v-tooltip="'Export to Excel'">
                            <export-excel :data="items_export" :name="generateExcelFileName()">
                                <img src="@/assets/imgs/icons/excel-icon.svg" alt="company-icon" class="excel__icon" />
                            </export-excel>
                        </div>
                    </div>
                    <v-overlay :value="loading">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                    <ag-grid-vue id="myGridSidebar" style="width: 100%; height: 650px;" class="ag-theme-alpine"
                        :columnDefs="columnDefs" :rowData="rowData" :domLayout="'autoHeight'"
                        :defaultColDef="defaultColDef" :pagination="true" :paginationPageSize="12" :pivotMode="false"
                        :rowSelection="'multiple'" :pinnedBottomRowData="pinnedBottomRowData" :getRowClass="getRowClass"
                        @grid-ready="onGridReady" :masterDetail="true">
                    </ag-grid-vue>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import axios from 'axios';
import { VAutocomplete } from 'vuetify/lib';

export default {
    components: {
        AgGridVue,
        VAutocomplete
    },
    props: ['profit', 'range', 'dates'],
    data() {
        return {
            dialog: false,
            loading: false,
            selectedWeek: this.dates && this.dates.length > 0 ? this.dates[0].display : null,
            fullSelectedWeek: null,

            selectedReportType: 'Pre-tax Profit',
            reportTypes: ['Pre-tax Profit', 'Sales Costs & Business Expenses', 'Other Income', 'Other Expenses', 'Financial Revenue', 'Financial Expenses'],
            rowData: [],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                filter: false,
                suppressMenuHide: true,
                menuTabs: ["filterMenuTab"],
            },
            pinnedBottomRowData: [{
                job_code: 'Tổng',
                revenue: 0,
                cost: 0,
                cp_com: 0,
                cp_fct: 0,
                profit: 0
            }],
            detailData: null,
            dataExport: [],
        };
    },
    computed: {
        columnDefs() {
            if (this.fullSelectedWeek.display === "Tổng hợp") {
                return [
                    {
                        headerName: "STT",
                        valueGetter: "node.rowIndex + 1",
                        maxWidth: 80,
                    },
                    { headerName: "Name", field: "quarter", minWidth: 100 },
                    { headerName: "Profit", field: "profit", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) },
                    {
                        headerName: "Sales Costs & Business Expenses",
                        field: "key_641_642",
                        minWidth: 100,
                        cellRenderer: params => {
                            const salesCosts = params.data.key_641 || 0;
                            const businessExpenses = params.data.key_642 || 0;
                            const total = salesCosts + businessExpenses;
                            return this.formatNumber(total);
                        }
                    },
                    { headerName: "Other Income", field: "key_711", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) },
                    { headerName: "Other expenses", field: "key_811", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) },
                    { headerName: "Financial Revenue", field: "key_515", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) },
                    { headerName: "Financial expenses", field: "key_635", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) },
                    { headerName: "Pre-tax Profit", field: "pre_tax_profit", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) }
                ]
            } else {
                if (this.selectedReportType === 'Pre-tax Profit') {
                    return [
                        {
                            headerName: "STT",
                            valueGetter: "node.rowIndex + 1",
                            maxWidth: 80,
                        },
                        { headerName: "Profit", field: "profit", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) },
                        {
                            headerName: "Sales Costs & Business Expenses",
                            field: "key_641_642",
                            minWidth: 100,
                            cellRenderer: params => {
                                const salesCosts = params.data.key_641 || 0;
                                const businessExpenses = params.data.key_642 || 0;
                                const total = salesCosts + businessExpenses;
                                return this.formatNumber(total);
                            }
                        },
                        { headerName: "Other Income", field: "key_711", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) },
                        { headerName: "Other expenses", field: "key_811", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) },
                        { headerName: "Financial Revenue", field: "key_515", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) },
                        { headerName: "Financial expenses", field: "key_635", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) },
                        { headerName: "Pre-tax Profit", field: "pre_tax_profit", minWidth: 100, cellRenderer: params => this.formatNumber(params.value) }
                    ];
                } else {
                    return [
                        {
                            headerName: "STT",
                            valueGetter: "node.rowIndex + 1",
                            maxWidth: 80,
                        },
                        { headerName: "Expense Item Code", field: "expense_item_code", minWidth: 150 },
                        { headerName: "Expense Item Name", field: "expense_item_name", minWidth: 200 },
                        { headerName: "Total Amount", field: "total_amount", minWidth: 150, cellRenderer: params => this.formatNumber(params.value) }
                    ];
                }
            }

        },
        items_export() {
            return this.rowData.map(data => {
                if (this.fullSelectedWeek.display === "Tổng hợp") {
                    return {
                        "Name": data.quarter,
                        "Profit": data.profit,
                        "Sales Costs": data.key_641,
                        "Business expenses": data.key_642,
                        "Other Income": data.key_711,
                        "Other expenses": data.key_811,
                        "Financial Revenue": data.key_515,
                        "Financial expenses": data.key_635,
                        "Pre-tax Profit": data.pre_tax_profit
                    };
                } else {
                    return this.selectedReportType === 'Pre-tax Profit' ? {
                        "Profit": data.profit,
                        "Sales Costs": data.key_641,
                        "Business expenses": data.key_642,
                        "Other Income": data.key_711,
                        "Other expenses": data.key_811,
                        "Financial Revenue": data.key_515,
                        "Financial expenses": data.key_635,
                        "Pre-tax Profit": data.pre_tax_profit
                    } : {
                        "Expense Item Code": data.expense_item_code,
                        "Expense Item Name": data.expense_item_name,
                        "Total Amount": data.total_amount
                    };
                }
            });
        },
        aggregatedData() {
            const combinedDates = {
                display: 'Tổng hợp',
                quarters: this.dates.map(quarter => ({
                    display: quarter.display,
                    start: quarter.start,
                    end: quarter.end
                }))
            };

            return [combinedDates].concat(this.dates.map(quarter => {
                return {
                    display: quarter.display,
                    start: quarter.start,
                    end: quarter.end
                };
            }));
        }
    },
    watch: {

        dialog(newVal) {
            if (newVal && this.aggregatedData && this.aggregatedData.length > 0) {
                this.selectedWeek = this.aggregatedData[0].display;
                this.fullSelectedWeek = this.aggregatedData[0];
                this.fetchData();
            }
        },
        selectedWeek(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.fullSelectedWeek = this.aggregatedData.find(date => date.display === newVal);
                if (this.fullSelectedWeek) {
                    this.fetchData();
                }
            }
        },
        selectedReportType(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.updateGridBasedOnReportType(newVal);
            }
        },

    },
    methods: {
        generateExcelFileName() {
            if (this.fullSelectedWeek?.display === 'Tổng hợp') {
                return 'Report Detail Synthetic';
            } else {
                return `Report Detail ${this.formatDateUtcToVn(this.fullSelectedWeek?.start)} - ${this.formatDateUtcToVn(this.fullSelectedWeek?.end)}`;
            }
        },
        updateGridBasedOnReportType(reportType) {
            if (reportType === 'Pre-tax Profit') {
                this.fetchData();
            } else {
                this.fetchData();
            }
        },
        formatDateUtcToVn(dateUtc) {
            const date = new Date(dateUtc);

            const vnTimeOffset = 7 * 60;
            const localTime = new Date(date.getTime() + vnTimeOffset * 60 * 1000);

            const day = localTime.getUTCDate();
            const month = localTime.getUTCMonth() + 1;
            const year = localTime.getUTCFullYear();

            const dayString = day < 10 ? '0' + day : day;
            const monthString = month < 10 ? '0' + month : month;

            return `${dayString}/${monthString}/${year}`;
        },
        formatNumber(value) {
            if (!value && value !== 0) return '';
            return value.toLocaleString('vi-VN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },
        fetchData() {
            this.loading = true;
            if (this.fullSelectedWeek.display === 'Tổng hợp') {
                this.fetchCombinedData(this.fullSelectedWeek.quarters);
            } else {
                if (this.selectedReportType === 'Pre-tax Profit') {
                    this.fetchPreTaxProfitData();
                } else {
                    this.fetchOtherData();
                }
            }
        },
        async fetchPreTaxProfitData() {
            try {
                const from_date_utc = this.fullSelectedWeek.start;
                const to_date_utc = this.fullSelectedWeek.end;

                const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';

                const response = await axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tai_khoan.php', {
                    params: {
                        from_date: from_date_utc,
                        to_date: to_date_utc,
                        account_number: '5113,632',
                        page_size: 1000000,
                        page_index: 1
                    }
                });
                const pageData = response.data.Data[key].PageData;

                const jobData = {};
                for (let account of pageData) {
                    const accountKey = account._key;

                    if (!account._data) {
                        console.error('PageData is missing for account:', accountKey);
                        continue;
                    }

                    for (let item of account._data) {
                        const jobCode = item.job_code;
                        if (!jobCode) continue;

                        if (!jobData[jobCode]) {
                            jobData[jobCode] = { revenue: 0, cost: 0, cp_com: 0, cp_fct: 0, key_641: 0, key_642: 0, key_711: 0, key_811: 0, key_515: 0, key_635: 0 };
                        }

                        if (accountKey === '5113') {
                            jobData[jobCode].revenue += parseFloat(item.credit_amount || 0);
                        } else if (accountKey === '632') {

                            if (item.expense_item_code) {
                                if (item.expense_item_code === 'CP.COM') {
                                    jobData[jobCode].cp_com += parseFloat(item.debit_amount || 0);
                                } else if (item.expense_item_code.includes('CP.FCT')) {
                                    jobData[jobCode].cp_fct += parseFloat(item.debit_amount || 0);
                                } else {
                                    jobData[jobCode].cost += parseFloat(item.debit_amount || 0);
                                }
                            } else {
                                jobData[jobCode].cost += parseFloat(item.debit_amount || 0);
                            }
                        }
                    }
                }

                const response641 = await axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tai_khoan.php', {
                    params: {
                        from_date: from_date_utc,
                        to_date: to_date_utc,
                        account_number: '641,642,711,811,515,635',
                        page_size: 1000000,
                        page_index: 1
                    }
                });
                const pageData641 = response641.data.Data[key].PageData;
                const keyAmounts = {
                    key_641: 0,
                    key_642: 0,
                    key_711: 0,
                    key_811: 0,
                    key_515: 0,
                    key_635: 0
                };

                for (let account of pageData641) {
                    for (let item of account._data) {
                        const account_number = item.account_number;
                        const description = item.description;
                        const debit_amount = parseFloat(item.debit_amount || 0);
                        const credit_amount = parseFloat(item.credit_amount || 0);

                        if (description === "Cộng") {
                            if (account_number === "641") {
                                keyAmounts.key_641 = debit_amount;
                            } else if (account_number === "642") {
                                keyAmounts.key_642 = debit_amount;
                            } else if (account_number === "711") {
                                keyAmounts.key_711 = credit_amount;
                            } else if (account_number === "811") {
                                keyAmounts.key_811 = debit_amount;
                            } else if (account_number === "515") {
                                keyAmounts.key_515 = credit_amount;
                            } else if (account_number === "635") {
                                keyAmounts.key_635 = debit_amount;
                            }
                        }
                    }
                }

                const formattedData = Object.entries(jobData).map(([jobCode, data]) => {
                    const profit = (data.revenue) - (data.cost + data.cp_com + data.cp_fct);
                    return {
                        job_code: jobCode,
                        profit: profit,
                        key_641_642: data.key_641 + data.key_642,
                    };
                });

                const totalProfit = formattedData.reduce((total, { profit }) => total + profit, 0);
                const dataAll = {
                    profit: totalProfit,
                    ...keyAmounts
                };

                this.processPreTaxProfitData(dataAll);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },
        async fetchCombinedData(quarters) {
            this.loading = true;
            const combinedData = [];

            for (const quarter of quarters) {
                const from_date_utc = quarter.start;
                const to_date_utc = quarter.end;

                try {
                    const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';

                    const response = await axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tai_khoan.php', {
                        params: {
                            from_date: from_date_utc,
                            to_date: to_date_utc,
                            account_number: '5113,632',
                            page_size: 1000000,
                            page_index: 1
                        }
                    });
                    const pageData = response.data.Data[key].PageData;

                    const jobData = {};
                    for (let account of pageData) {
                        const accountKey = account._key;

                        if (!account._data) {
                            continue;
                        }

                        for (let item of account._data) {
                            const jobCode = item.job_code;
                            if (!jobCode) continue;

                            if (!jobData[jobCode]) {
                                jobData[jobCode] = { revenue: 0, cost: 0, cp_com: 0, cp_fct: 0, key_641: 0, key_642: 0, key_711: 0, key_811: 0, key_515: 0, key_635: 0 };
                            }

                            if (accountKey === '5113') {
                                jobData[jobCode].revenue += parseFloat(item.credit_amount || 0);
                            } else if (accountKey === '632') {

                                if (item.expense_item_code) {
                                    if (item.expense_item_code === 'CP.COM') {
                                        jobData[jobCode].cp_com += parseFloat(item.debit_amount || 0);
                                    } else if (item.expense_item_code.includes('CP.FCT')) {
                                        jobData[jobCode].cp_fct += parseFloat(item.debit_amount || 0);
                                    } else {
                                        jobData[jobCode].cost += parseFloat(item.debit_amount || 0);
                                    }
                                } else {
                                    jobData[jobCode].cost += parseFloat(item.debit_amount || 0);
                                }
                            }
                        }
                    }

                    const response641 = await axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tai_khoan.php', {
                        params: {
                            from_date: from_date_utc,
                            to_date: to_date_utc,
                            account_number: '641,642,711,811,515,635',
                            page_size: 1000000,
                            page_index: 1
                        }
                    });
                    const pageData641 = response641.data.Data[key].PageData;
                    const keyAmounts = {
                        key_641: 0,
                        key_642: 0,
                        key_711: 0,
                        key_811: 0,
                        key_515: 0,
                        key_635: 0
                    };

                    for (let account of pageData641) {
                        for (let item of account._data) {
                            const account_number = item.account_number;
                            const description = item.description;
                            const debit_amount = parseFloat(item.debit_amount || 0);
                            const credit_amount = parseFloat(item.credit_amount || 0);

                            if (description === "Cộng") {
                                if (account_number === "641") {
                                    keyAmounts.key_641 = debit_amount;
                                } else if (account_number === "642") {
                                    keyAmounts.key_642 = debit_amount;
                                } else if (account_number === "711") {
                                    keyAmounts.key_711 = credit_amount;
                                } else if (account_number === "811") {
                                    keyAmounts.key_811 = debit_amount;
                                } else if (account_number === "515") {
                                    keyAmounts.key_515 = credit_amount;
                                } else if (account_number === "635") {
                                    keyAmounts.key_635 = debit_amount;
                                }
                            }
                        }
                    }

                    const formattedData = Object.entries(jobData).map(([jobCode, data]) => {
                        const profit = (data.revenue) - (data.cost + data.cp_com + data.cp_fct);
                        return {
                            job_code: jobCode,
                            profit: profit,
                            key_641_642: data.key_641 + data.key_642,
                        };
                    });

                    const quarterData = {
                        quarter: quarter.display,
                        profit: formattedData.reduce((sum, item) => sum + item.profit, 0),
                        key_641: keyAmounts.key_641,
                        key_642: keyAmounts.key_642,
                        key_711: keyAmounts.key_711,
                        key_811: keyAmounts.key_811,
                        key_515: keyAmounts.key_515,
                        key_635: keyAmounts.key_635,
                        pre_tax_profit: formattedData.reduce((sum, item) => sum + item.profit, 0) - keyAmounts.key_641 - keyAmounts.key_642 + (keyAmounts.key_711 - keyAmounts.key_811) + (keyAmounts.key_515 - keyAmounts.key_635)
                    };

                    combinedData.push(quarterData);

                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }

            this.rowData = combinedData;

            this.loading = false;
            this.calculateTotals();
            if (this.gridApi) {
                this.gridApi.setRowData(this.rowData);
            }
        },
        processPreTaxProfitData(dataAll) {
            console.log(dataAll);
            this.rowData = [{
                job_code: 'Tổng',
                profit: dataAll.profit,
                key_641: dataAll.key_641,
                key_642: dataAll.key_642,
                key_711: dataAll.key_711,
                key_811: dataAll.key_811,
                key_515: dataAll.key_515,
                key_635: dataAll.key_635,
                pre_tax_profit: dataAll.profit - dataAll.key_641 - dataAll.key_642 + (dataAll.key_711 - dataAll.key_811) + (dataAll.key_515 - dataAll.key_635)
            }];
            this.calculateTotals();
        },
        fetchOtherData() {
            const accountKeyMapping = {
                'Sales Costs & Business Expenses': '641,642',
                'Other Income': '711',
                'Other Expenses': '811',
                'Financial Revenue': '515',
                'Financial Expenses': '635'
            };
            const accountKey = accountKeyMapping[this.selectedReportType];

            const params = {
                from_date: this.fullSelectedWeek.start,
                to_date: this.fullSelectedWeek.end,
                account_number: accountKey,
                page_size: 1000000,
                page_index: 1
            };

            axios.get('https://api-misa.zigisoft.com/get_report_chi_tiet_tai_khoan.php', { params })
                .then(response => {
                    const key = 'eede93bd-5035-48c0-a2c1-374aee1220eb';
                    const data = response.data.Data[key].PageData;
                    if (this.selectedReportType === 'Sales Costs & Business Expenses') {
                        this.processSalesAndBusinessExpensesData(data);
                    } else {
                        this.processOtherData(data);
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        processSalesAndBusinessExpensesData(data) {
            const expenseDetails = {};

            data.forEach(account => {
                if (!account._data) {
                    console.error('PageData is missing for account:', account._key);
                    return;
                }

                account._data.forEach(item => {
                    const amount = parseFloat(item.debit_amount || 0);
                    const expenseItemCode = item.expense_item_code;
                    const expenseItemName = item.expense_item_name;
                    if (!expenseItemCode) {
                        return;
                    }
                    if (!expenseDetails[expenseItemCode]) {
                        expenseDetails[expenseItemCode] = {
                            expense_item_code: expenseItemCode,
                            expense_item_name: expenseItemName,
                            amount_641: 0,
                            amount_642: 0
                        };
                    }

                    if (account._key === '641') {
                        expenseDetails[expenseItemCode].amount_641 += amount;
                    } else if (account._key === '642') {
                        expenseDetails[expenseItemCode].amount_642 += amount;
                    }
                });
            });

            this.rowData = Object.values(expenseDetails).map(item => {
                const netAmount = item.amount_641 + item.amount_642;
                return {
                    ...item,
                    total_amount: netAmount
                };
            });

        },
        processOtherData(data) {
            const expenseData = {};

            data.forEach(account => {
                if (!account._data) {
                    console.error('PageData is missing for account:', account._key);
                    return;
                }

                account._data.forEach(item => {
                    if (!item.expense_item_code) return;
                    const expenseItemCode = item.expense_item_code;
                    const expenseItemName = item.expense_item_name;
                    const amount = this.selectedReportType === 'Other Income' || this.selectedReportType === 'Financial Revenue' ? parseFloat(item.credit_amount || 0) : parseFloat(item.debit_amount || 0);

                    if (!expenseData[expenseItemCode]) {
                        expenseData[expenseItemCode] = {
                            expense_item_code: expenseItemCode,
                            expense_item_name: expenseItemName,
                            total_amount: 0,
                            jobs: []
                        };
                    }

                    expenseData[expenseItemCode].total_amount += amount;

                });
            });

            this.rowData = Object.values(expenseData);
            this.calculateTotals();
        },

        calculateTotals() {
            if (this.selectedReportType !== 'Pre-tax Profit') {
                let totalAmount = 0;
                this.rowData.forEach(row => {
                    totalAmount += row.total_amount;
                });
                this.pinnedBottomRowData = [{
                    expense_item_code: 'Tổng',
                    total_amount: totalAmount
                }];
            }
        },
        getRowClass(params) {
            if (params.node.rowPinned) {
                return 'bold-text';
            }
            return '';
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            this.fetchData();
            this.gridApi.addEventListener('paginationChanged', this.calculateTotals);
        },
        reloadData() {
            this.fetchData();
            this.calculateTotals();
        },
        openDialog() {
            this.dialog = true;
            this.fetchData();
        },
    }
}
</script>

<style>
.btn_reload {
    margin-right: 10px;
    margin-top: 4px;
    cursor: pointer;
}

.select-list {
    display: flex;
    gap: 15px;
}

.reload__icon__reload {
    filter: invert(0%) sepia(58%) saturate(2088%) hue-rotate(45deg) brightness(88%) contrast(99%);
    width: 33px;
    height: 34px;
    cursor: pointer;
}

.display-mode-select {
    margin-right: 10px;
    width: 200px;
}

.display-mode-select-two {
    width: 340px;
}

.bold-text {
    font-weight: bold;
}
</style>
